import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SpyglassSearchModule } from './spyglass-search/spyglass-search.module';
import { SpyglassChatModule } from './spyglass-chat/spyglass-chat.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { LockoutComponent } from "./lockout/lockout.component";
import { MicrosoftCallbackComponent } from './spyglass-search/microsoft-callback/microsoft-callback.component';
import { OidcCallbackComponent } from './spyglass-search/oidc-callback/oidc-callback.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


// Updated to use MDC-based Material components
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PreChatComponent } from './pre-chat/pre-chat.component';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PreChatComponent,
    LockoutComponent,
    MicrosoftCallbackComponent,
    OidcCallbackComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    FormsModule,
    SpyglassSearchModule,
    SpyglassChatModule,
    AppRoutingModule,
    RouterModule, // Added RouterModule for `router-outlet`
    BrowserModule,
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/galaxy'
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
