<form 
  class="d-flex align-items-center chat-box-form" 
  [formGroup]="searchForm" 
  (ngSubmit)="onSubmit()"
>
  <textarea
    #inputElement
    class="form-control chat-textarea"
    formControlName="query"
    [placeholder]="searchBarPlaceholder"
    aria-label="Chat input"
    rows="3"
    (keydown)="handleKeyDown($event)"
  ></textarea>
  
  <button 
    type="submit" 
    class="btn btn-primary chat-send-btn ms-3" 
    [disabled]="isLoading || isSearchButtonDisabled"
  >
    <span *ngIf="!isLoading">{{ searchButtonLabel }}</span>
    <div *ngIf="isLoading" class="spinner-border" role="status"></div>
  </button>
</form>
