import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchService } from '../search.service';
import { WebSocketService } from '../websockets.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  standalone: false,
  encapsulation: ViewEncapsulation.None
})
export class SummaryComponent implements OnInit, OnDestroy {

  text: string = '';
  provider: string = '';
  nameMappings: { [key: string]: any } = {
    'CHATGPT': 'ChatGPT'
  };
  isLoading: boolean = true;
  isShowing: boolean = false;
  lastSearchId: any;
  ragItems: any;
  isRagParamInUrl: any;
  ragTimeout: any;
  qSummaryTime: number;
  qSummaryTimeSeconds: number;
  aiModel: string | boolean;

  private webSocketConnection: any;
  private webSocketSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private searchService: SearchService,
    private webSocketService: WebSocketService,
    private route: ActivatedRoute
  ) {
    this.text = '';
    this.provider = '';
    this.lastSearchId = false;
    this.ragItems = [];
    this.webSocketSubscription = new Subscription();
    this.isRagParamInUrl = false;
    this.ragTimeout = 0;
    this.qSummaryTime = 0;
    this.qSummaryTimeSeconds = 0;
    this.aiModel = false;
  }

  isNumberOrStringNumber(value: any): number | null {
    if (typeof value === 'number' && isFinite(value)) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedNumber = parseInt(value, 10);
      if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
        return parsedNumber;
      }
    }
    return null;
  }

  destroyWebSocketConnection() {
    if (this.webSocketConnection) {
      console.log("DESTROY SOCKET");
      this.webSocketConnection.complete();
      this.webSocketConnection = null;
    }
    this.webSocketService.disable();
  }

  async createWebSocketConnection(searchId: any, ragItems: any) {
    this.destroyWebSocketConnection();
    console.log("CREATE SOCKET");
    this.webSocketConnection = await this.webSocketService.init(this.authService.getToken(), searchId, ragItems);
  }

  initiateWebSocketDataFlow() {
    console.log("Initiating WebSocket data flow");

    this.isLoading = true;
    this.webSocketService.time.next(0);
    this.searchService.websocketLoading.next(true);

    // Capture the start time before sending the request.
    const requestStartTime = Date.now();

    this.webSocketService.send('');

    let websocketTimeout = this.isNumberOrStringNumber(this.webSocketService.timeout);
    if (!websocketTimeout) {
      throw new Error("Invalid WebSocket timeout value");
    }

    const ragTimeoutParsed = this.isNumberOrStringNumber(this.ragTimeout);
    if (ragTimeoutParsed !== null) {
      websocketTimeout = ragTimeoutParsed * 1000;
    }

    this.webSocketService
      .receive()
      .pipe(timeout(websocketTimeout))
      .subscribe({
        next: (response: any) => {
          let messageContent = '';
          let additionalContent: any = {};

          // Check if response is structured as ai_summary array; if not, use top-level fields.
          if (response.ai_summary && Array.isArray(response.ai_summary) && response.ai_summary.length > 0) {
            messageContent = response.ai_summary[0].body[0];
            additionalContent = response.ai_summary[0].additional_content || {};
          } else {
            messageContent = response.message;
            additionalContent = response.additional_content || {};
          }

          // Compute the response time using the captured start time.
          const responseTime = Date.now() - requestStartTime;
          this.qSummaryTime = responseTime;
          this.qSummaryTimeSeconds = Math.round(responseTime / 100) / 10;

          this.isLoading = false;
          this.searchService.websocketLoading.next(false);

          let sanitizedMessage = this.sanitizeText(messageContent);
          sanitizedMessage = this.styleSources(sanitizedMessage);

          // Capture the AI model for metadata display
          if (response.ai_model) {
            this.aiModel = response.ai_model;
            this.webSocketService.aiModelChange.next(response.ai_model);
          }

          // Build Sources block without inline favicon <img> tags.
          if (
            additionalContent.sources &&
            Array.isArray(additionalContent.sources) &&
            additionalContent.sources.length > 0
          ) {
            let sourcesHtml = `<div class="sources-list"><p class="sources-header">Sources:</p><ul class="sources-list-ul">`;
            additionalContent.sources.forEach((source: any) => {
              const cleanUrl = source.url.replace(/<em>/gi, '').replace(/<\/em>/gi, '');
              const cleanDescription = source.description.replace(/<em>/gi, '').replace(/<\/em>/gi, '');
              sourcesHtml += `<li class="sources-list-li">
                                <a class="sources-list-link" target="_blank" href="${cleanUrl}">
                                  <span class="me-2">${cleanDescription}</span>
                                </a>
                              </li>`;
            });
            sourcesHtml += `</ul></div>`;
            sanitizedMessage += sourcesHtml;
          }

          this.text = sanitizedMessage;
          // Use post-render injection to add favicons.
          setTimeout(() => {
            this.addFavicons();
          }, 0);
          this.destroyWebSocketConnection();
        },
        error: (error) => {
          if (error.name === 'TimeoutError') {
            this.webSocketService.send('stop');
            this.text = this.webSocketService.timeoutText;
            this.isLoading = false;
            this.searchService.websocketLoading.next(false);
            this.destroyWebSocketConnection();
          } else if (error.message === 'There are no AIProviders configured.') {
            this.text = error.message;
            this.isLoading = false;
            this.searchService.websocketLoading.next(false);
          } else {
            console.error("WebSocket error:", error);
          }
        }
      });
  }

  getFaviconUrl(url: string): string {
    const cleanUrl = url.replace(/<em>/gi, '').replace(/<\/em>/gi, '');
    try {
      const domain = new URL(cleanUrl).origin;
      return `${domain}/favicon.ico`;
    } catch (e) {
      return '';
    }
  }

  sanitizeText(text: string) {
    return text.replace(
      /<a class="question-link">([\s\S]*?)<\/a>/g,
      (_match: any, innerContent: string) => {
        const sanitizedContent = innerContent.trim()
          .replace(/\s+/g, ' ')
          .replace(/<[^>]*>/g, '')
          .replace('?', '');
        let link = `/galaxy/search?q=${encodeURIComponent(sanitizedContent)}`;
        if (this.searchService.searchInfo.value.search.searchprovider_list) {
          const providers = this.searchService.searchInfo.value.search.searchprovider_list.join(',');
          link += `&providers=${encodeURIComponent(providers)}`;
        }
        link += '&rag=true';
        const prompt = this.route.snapshot.queryParamMap.get('prompt') || '';
        if (prompt) {
          link += `&prompt=${encodeURIComponent(prompt)}`;
        }
        return `<a class="question-link" href="${link}">${innerContent}</a>`;
      }
    );
  }

  private styleSources(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const sourcesHeader = Array.from(doc.querySelectorAll('b')).find(
      (b) => b.innerText.trim() === 'Sources:'
    );
    if (sourcesHeader) {
      if (sourcesHeader.previousSibling?.nodeName === 'BR') {
        sourcesHeader.previousSibling.remove();
      }
      const headerWrapper = document.createElement('p');
      headerWrapper.className = 'sources-header';
      headerWrapper.innerHTML = sourcesHeader.outerHTML;
      sourcesHeader.replaceWith(headerWrapper);
    }
    return doc.body.innerHTML;
  }

  private addFavicons(): void {
    const sourceItems = document.querySelectorAll('.sources-list-li');
    sourceItems.forEach((item) => {
      const link = item.querySelector('a');
      if (link) {
        const href = link.getAttribute('href');
        if (href) {
          try {
            const domain = new URL(href).origin;
            const faviconUrl = `${domain}/favicon.ico`;
  
            const img = document.createElement('img');
            img.src = faviconUrl;
            img.alt = `${domain} favicon`;
            img.className = 'source-favicon';
            img.onerror = () => {
              img.style.display = 'none';
            };
  
            // Prepend the favicon image to the <li> if not already present.
            if (!item.querySelector('img.source-favicon')) {
              item.prepend(img);
            }
          } catch (e) {
            // Do nothing if URL construction fails
          }
        }
      }
    });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.isRagParamInUrl = params.get('rag');
      this.ragTimeout = params.get('rag_timeout');
    });
    this.searchService.selectedRagItems.subscribe((ragItems: any) => this.ragItems = ragItems);
    this.webSocketSubscription = this.webSocketService.webSocketChanged.subscribe(async ([isEnabled, isNewSearch]) => {
      if (isNewSearch)
        this.lastSearchId = false;
      if (!isEnabled) {
        this.isShowing = false;
        console.log("Disable 'Generate AI response'");
        this.destroyWebSocketConnection();
      } else {
        console.log("Enable 'Generate AI response'");
        if (this.lastSearchId) {
          await this.createWebSocketConnection(this.lastSearchId, this.ragItems);
          this.isShowing = true;
          this.initiateWebSocketDataFlow();
        }
      }
    });
    this.searchService.currentSearchInfo.subscribe(async (info) => {
      if (info?.search?.id && !this.lastSearchId) {
        this.lastSearchId = info.search.id;
        if (this.webSocketService.isEnabled()) {
          console.log('WebSocket enabled');
          await this.createWebSocketConnection(info.search.id, this.ragItems);
          this.isShowing = true;
          this.initiateWebSocketDataFlow();
        }
      }
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        this.isLoading = true;
        this.isShowing = false;
        this.lastSearchId = false;
        this.webSocketService.changeStatus(false, true);
        this.ragItems = [];
        this.webSocketService.time.next(0);
      }
    });
    this.searchService.isNewSearch.subscribe(isNewSearch => {
      if (isNewSearch) {
        this.isLoading = true;
        this.isShowing = false;
      }
    });
    this.searchService.isLoading.subscribe(isLoading => {
      this.lastSearchId = !isLoading ? this.lastSearchId : false;
    });
    this.searchService.isNewSearch.subscribe(isNewSearch => this.webSocketService.time.next(0));
    this.webSocketService.responseTime.subscribe((time) => {
      this.qSummaryTime = time;
      this.qSummaryTimeSeconds = Math.round(this.qSummaryTime / 100) / 10;
    });
    this.webSocketService.aiModelName.subscribe((model) => {
      this.aiModel = model;
    });
  }

  ngOnDestroy() {
    this.destroyWebSocketConnection();
    if (this.webSocketSubscription)
      this.webSocketSubscription.unsubscribe();
  }
}
