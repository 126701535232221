import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatProfileBoxComponent } from './chat-profile-box/chat-profile-box.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpyglassChatComponent } from './spyglass-chat/spyglass-chat.component';
import { ChatComponent } from './chat/chat.component';
import { ChatAuthorshipFieldComponent } from './chat-authorship-field/chat-authorship-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfigService } from './config.service';
import { MsalAccessService } from '../auth/msal-service';
import { OauthService } from '../auth/oauth2-service';
import { WebSocketService } from './websockets.service';
import { firstValueFrom, timer } from 'rxjs';
import { switchMap, filter, first, tap } from 'rxjs/operators';
import { ConfigResponse } from '../spyglass-search/shared/config-response';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { CookieConsentService } from '../shared/cookie-consent.service';

export function initializeApp(
  configService: ConfigService,
  msalService: MsalAccessService,
  oauthService: OauthService,
  webSocketService: WebSocketService
) {
  return () => {
    const pollingInterval = 1000; // Interval between polling attempts (in milliseconds)

    return firstValueFrom(
      timer(0, pollingInterval).pipe(
        tap(() => console.log('Polling for config...')),
        switchMap(() => configService.getConfig('default')),
        tap((configResponse) => console.log('Config response:', configResponse)),
        filter(
          (configResponse): configResponse is ConfigResponse =>
            configResponse !== null && configResponse.msalConfig !== null
        ),
        first() // Ensures that the Observable completes after the first matching value
      )
    )
      .then((configResponse) => {
        if (!configResponse) {
          console.error('Config response is undefined.');
          return; // Early return if configResponse is undefined
        }
        const oauthConf = configResponse.oauthConfig;
        const msalConf = configResponse.msalConfig;
        const shouldUseTokenFromOauth = configResponse.shouldUseTokenFromOauth ?? false;
        const webSocketConf = configResponse.webSocketConfig;
        const microsoftGalaxyOauth = configResponse.microsoftGalaxyOauth;
        if (oauthConf) oauthService.loadConfig(oauthConf);
        if (webSocketConf) webSocketService.loadConfig(webSocketConf);
        msalService.loadConfig(msalConf, shouldUseTokenFromOauth, microsoftGalaxyOauth);
      })
      .catch((error) => {
        console.error('Error during app initialization:', error);
      });
  };
}

@NgModule({
  declarations: [
    ChatBoxComponent,
    ChatProfileBoxComponent,
    SpyglassChatComponent,
    ChatComponent,
    ChatAuthorshipFieldComponent
  ],
  exports: [
    ChatProfileBoxComponent,
    SpyglassChatComponent,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    ConfigService,
    MsalAccessService,
    OauthService,
    WebSocketService,
    CookieConsentService,
    provideAppInitializer(() => {
      const initializerFn = initializeApp(
        inject(ConfigService),
        inject(MsalAccessService),
        inject(OauthService),
        inject(WebSocketService)
      );
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SpyglassChatModule {}
