import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  private cookieConsentInstance: any;

  constructor(@Inject(DOCUMENT) private document: Document, private authService: AuthService) {}

  initCookieConsent(): void {
    // Check the cookie consent flag
    const consent = this.authService.getCookieConsent();
    if (!consent) {
      console.log('Skipping CookieConsent initialization due to flag.');
      return;
    }

    const checkInterval = setInterval(() => {
      const cookieconsent = (this.document.defaultView as any)?.cookieconsent;
      if (cookieconsent && typeof cookieconsent.initialise === 'function') {
        clearInterval(checkInterval);

        try {
          this.cookieConsentInstance = cookieconsent.initialise({
            theme: 'classic',
            type: 'info',
            revokable: false,
            content: {
              message: 'This website uses cookies to ensure you get the best experience on our website.',
              allow: 'Got it!',
              link: 'Learn more',
              href: 'https://swirlaiconnect.com/privacy-policy',
            },
          });

          console.log('Cookie consent initialized successfully.');
        } catch (error) {
          console.error('Failed to initialize CookieConsent:', error);
        }
      }
    }, 200);
  }

  destroyCookieConsent(): void {
    if (this.cookieConsentInstance) {
      try {
        this.cookieConsentInstance.hide();
        this.cookieConsentInstance = null;
        console.log('CookieConsent instance destroyed.');
      } catch (error) {
        console.error('Error during CookieConsent cleanup:', error);
      }
    }
  }
}
