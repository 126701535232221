import { Injectable } from '@angular/core';
import { Refinement, SearchContext } from './shared/search-context';
import { BehaviorSubject, Observable } from 'rxjs';
import { Result } from './shared/result';
import { Bucket, Facet, Facets } from "./shared/facets";
import { ConfigService } from "./config.service";
import { SwirlResponse, SwirlService } from "./swirl.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private readonly RESULT_MIXER_KEY = 'result_mixer';
  private readonly DEFAULT_RESULT_MIXER_VALUE = 'RelevancyConfidenceMixer';
  private readonly DEFAULT_RESULT_MIXER = this.RESULT_MIXER_KEY + ':' + this.DEFAULT_RESULT_MIXER_VALUE;

  // Initial search context
  private defaultContext = new SearchContext();
  private searchContextSource = new BehaviorSubject<SearchContext>(this.defaultContext);
  currentSearchContext = this.searchContextSource.asObservable();

  private defaultResult = { 'docs': [], maxScore: 0, numFound: 0, start: 0, numRetrieved: 0 };
  private resultSource = new BehaviorSubject<Result>(this.defaultResult);
  currentResults = this.resultSource.asObservable();

  private defaultSearchInfo = {};
  public searchInfo = new BehaviorSubject<any>(this.defaultSearchInfo);
  currentSearchInfo = this.searchInfo.asObservable();

  private logoClickEvent = false;
  private _logoClickEvent = new BehaviorSubject<any>(this.logoClickEvent);
  eraseEvent = this._logoClickEvent.asObservable();

  // Use plain object for header instead of SolrHeader
  private defaultHeader = { QTime: 0, status: 0, date_retrieved: null };
  private resultHeaderSource = new BehaviorSubject<any>(this.defaultHeader);
  currentResultHeader = this.resultHeaderSource.asObservable();

  private defaultHighlights = {};
  private highlightsSource = new BehaviorSubject<Object>(this.defaultHighlights);
  currentHighlights = this.highlightsSource.asObservable();

  private firstFacets = { count: 0 };
  private defaultFacets = { count: 0 };
  private facetsSource = new BehaviorSubject<Facets>(this.defaultFacets);
  currentFacets = this.facetsSource.asObservable();

  // Change error type to any
  private defaultError = undefined;
  private errorSource = new BehaviorSubject<any>(this.defaultError);
  currentError = this.errorSource.asObservable();

  private loading = new BehaviorSubject<boolean>(false);
  isLoading = this.loading.asObservable();

  private cardView = new BehaviorSubject<boolean>(true);
  isCardView = this.cardView.asObservable();

  private searched = new BehaviorSubject<boolean>(false);
  isSearched = this.searched.asObservable();

  private mixer = new BehaviorSubject<string>('RelevancyMixer');
  resultMixer = this.mixer.asObservable();

  public selectedItems = new BehaviorSubject<boolean>(false);
  isSelectedItems = this.selectedItems.asObservable();

  public _selectedRagItems = new BehaviorSubject<Array<any>>([]);
  selectedRagItems = this._selectedRagItems.asObservable();

  public _ragItems = new BehaviorSubject<any>({});
  ragItems = this._ragItems.asObservable();

  public websocketLoading = new BehaviorSubject<boolean>(false);
  isWebsocketLoading = this.websocketLoading.asObservable();

  public newSearch = new BehaviorSubject<boolean>(false);
  isNewSearch = this.newSearch.asObservable();

  public enabledCharts = new BehaviorSubject<boolean>(false);
  isEnabledCharts = this.enabledCharts.asObservable();

  public cleanAllToggle = new BehaviorSubject<boolean>(false);
  isCleanAllToggle = this.cleanAllToggle.asObservable();

  // Removed SolrService from the constructor—only using SwirlService now.
  constructor(private swirlService: SwirlService, private configService: ConfigService) {
    // Initialize enabledCharts based on localStorage
    const storedValue = localStorage.getItem('isEnabledCharts');
    const initialState = storedValue !== null ? JSON.parse(storedValue) : false; // Default to false
    this.enabledCharts = new BehaviorSubject<boolean>(initialState);
    this.isEnabledCharts = this.enabledCharts.asObservable();
    
    configService.getConfig().subscribe(configResponse => {
      if (configResponse) {
        this.defaultContext = SearchContext.newSearchContext(configResponse);
        this.searchContextSource.next(this.defaultContext);
      }
    });
  }

  updateEnabledChartsState(state: boolean): void {
    this.enabledCharts.next(state);
    localStorage.setItem('isEnabledCharts', JSON.stringify(state));
  }

  addToSelectedItems(item: any, isClicked: any, score: any) {
    let currentItems = this._selectedRagItems.value;
    const shouldAdd = this._ragItems.value[item] == undefined ? score > 50 : this._ragItems.value[item];
    if (shouldAdd || isClicked) {
      currentItems.push(item);
      currentItems = [...new Set(currentItems)];
      currentItems.sort((a, b) => a - b);
      this._selectedRagItems.next(currentItems);
      this._ragItems.next({
        ...this._ragItems.value,
        [item]: true
      });
      return shouldAdd || isClicked || score > 50;
    }
    return false;
  }

  removeFromSelectedItems(item: any) {
    const currentItems = this._selectedRagItems.value;
    const updatedItems = currentItems.filter((i) => i !== item);
    this._selectedRagItems.next(updatedItems);
    this._ragItems.next({
      ...this._ragItems.value,
      [item]: false
    });
  }

  clearSelectedItems() {
    this._selectedRagItems.next([]);
    this._ragItems.next({});
  }

  isItemSelected(item: any) {
    return this._selectedRagItems.value.includes(item);
  }

  clearResults(): void {
    if (this.resultSource.getValue() !== this.defaultResult) {
      this.resultSource.next(this.defaultResult);
    }
  
    if (this.searched.getValue()) {
      this.searched.next(false);
    }
  }

  changeEraseEvent(value: boolean): void {
    this._logoClickEvent.next(value);
    if (value) {
      this.clearResults();
    }
  }

  changeSearch(searchContext: SearchContext) {
    // Guard clause: clear results if query is empty or whitespace.
    if (!searchContext.query || searchContext.query.trim() === '') {
      this.clearResults();
      return;
    }

    this.loading.next(true);
    this.searched.next(true);
    // Always use swirl search now that solr references have been removed.
    this.swirlService.search(searchContext).then((response: Observable<SwirlResponse>) => {
      response.subscribe({
        next: (swirlResponse: SwirlResponse) => {
          this.searchContextSource.next(searchContext);
          const result = new Result();
          swirlResponse.results?.forEach((sResult: any) => {
            if (sResult.title && sResult.title.length > 0) {
              result.docs.push(sResult);
            } else {
              swirlResponse.info.results.retrieved = swirlResponse?.info?.results?.retrieved - 1 || 0;
              swirlResponse.info.results.retrieved_total = swirlResponse?.info?.results?.retrieved_total - 1 || 0;
              if (swirlResponse.info[sResult.searchprovider]) {
                if (swirlResponse.info[sResult.searchprovider].retrieved == 1) {
                  delete swirlResponse.info[sResult.searchprovider];
                } else {
                  swirlResponse.info[sResult.searchprovider].retrieved = swirlResponse.info[sResult.searchprovider].retrieved - 1;
                }
              }
            }
          });
          this.searchInfo.next(swirlResponse?.info);
          result.numFound = swirlResponse.info?.results?.found_total as number;
          result.numRetrieved = swirlResponse.info?.results?.retrieved_total as number;
          searchContext.searchId = swirlResponse.info?.search?.id as string;
          let facets = new Facets();
          if (this.firstFacets.count == 0) {
            facets.count = swirlResponse.info?.results?.retrieved_total as number;
            const infos = swirlResponse.info;
            const providerFacet = new Facet();
            providerFacet.buckets = new Array<Bucket>();
            for (let key in infos) {
              if (key !== 'search' && key !== 'results') {
                const bucket = new Bucket();
                bucket.val = infos[key]['filter_url'].split('=').at(-1);
                bucket.label = key;
                bucket.count = infos[key]['retrieved'];
                providerFacet.buckets.push(bucket);
              }
            }
            facets['Source'] = providerFacet;
            this.firstFacets = facets;
          } else {
            facets = this.firstFacets;
          }
          this.facetsSource.next(facets);
          this.resultSource.next(result);
          this.resultHeaderSource.next({ QTime: swirlResponse.info?.results?.federation_time * 1000, status: 0, date_retrieved: swirlResponse.results?.[0]?.date_retrieved });
          this.loading.next(false);
        },
        error: (wrapper: any) => {
          console.log('error', wrapper);
          this.loading.next(false);
        }
      });
    });
  }

  changeQuery(query: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.query = query;
    newSearch.start = 0;
    newSearch.page = 1;
    newSearch.searchId = '';
    this.changeSearch(newSearch);
  }

  changeQueryAndProviders(query: string, providers: Array<string> | Array<number>, connectorsNames: Array<string>) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.query = query;
    newSearch.providers = providers;
    newSearch.refine = [];
    newSearch.connectorsNames = connectorsNames;
    newSearch.start = 0;
    newSearch.page = 1;
    newSearch.searchId = '';
    this.firstFacets = { count: 0 };
    this.facetsSource.next({ count: 0 });
    this.newSearch.next(true);
    this.changeSearch(newSearch);
  }

  changeProviders(providers: Array<string>) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.providers = providers;
    newSearch.start = 0;
    newSearch.page = 1;
    newSearch.searchId = '';
    this.changeSearch(newSearch);
  }

  changePage(page: number, start: number) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.page = page;
    newSearch.start = start;
    this.changeSearch(newSearch);
  }

  changeRows(rows: number) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.rows = rows;
    newSearch.start = 0;
    this.changeSearch(newSearch);
  }

  changeSort(sort: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.sort = sort;
    this.changeSearch(newSearch);
  }

  changeDefType(defType: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.defType = defType;
    this.searchContextSource.next(newSearch);
  }

  refine(refinement: Refinement) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.refine.push(refinement);
    newSearch.start = 0;
    newSearch.page = 1;
    this.changeSearch(newSearch);
  }

  refineMultiple(field: string, refinements: Array<Refinement>) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.refine = newSearch.refine.filter((refinement) => refinement.field != field);
    newSearch.refine = refinements;
    newSearch.start = 0;
    newSearch.page = 1;
    this.changeSearch(newSearch);
  }

  getRefine() {
    return this.searchContextSource.getValue().refine;
  }

  removeRefinement(index: number) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.refine.splice(index, 1);
    newSearch.start = 0;
    newSearch.page = 1;
    this.changeSearch(newSearch);
  }

  resetAll() {
    const newSearch = this.searchContextSource.getValue();
    newSearch.query = "*";
    newSearch.refine = [];
    newSearch.start = 0;
    newSearch.page = 1;
    newSearch.searchId = '';
    this.changeSearch(newSearch);
  }

  resetFacets() {
    const newSearch = this.searchContextSource.getValue();
    newSearch.refine = [];
    newSearch.start = 0;
    newSearch.page = 1;
    newSearch.searchId = '';
    this.changeSearch(newSearch);
  }

  getFilter(key: string) {
    const newSearch = this.searchContextSource.getValue();
    const filter = newSearch.filters.get(key);
    return filter;
  }

  putFilter(key: string, filter: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.filters.set(key, filter);
    newSearch.start = 0;
    newSearch.page = 1;
    if (key === 'result_mixer')
      this.mixer.next(filter?.split(':')?.[1]);
    this.changeSearch(newSearch);
  }

  putFilterWithoutSearch(key: string, filter: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.filters.set(key, filter);
    newSearch.start = 0;
    newSearch.page = 1;
    if (key === 'result_mixer')
      this.mixer.next(filter?.split(':')?.[1]);
  }

  removeFilter(key: string) {
    const newSearch = this.searchContextSource.getValue();
    newSearch.filters.delete(key);
    newSearch.start = 0;
    newSearch.page = 1;
    if (key === this.RESULT_MIXER_KEY) {
      const fallbackMixer = newSearch.filters.get(this.RESULT_MIXER_KEY) || this.DEFAULT_RESULT_MIXER;
      const splitted = fallbackMixer.split(':');
      const mixerValue = splitted[1] || this.DEFAULT_RESULT_MIXER_VALUE;
      this.mixer.next(mixerValue);
    }
    this.changeSearch(newSearch);
  }

  toggleView() {
    this.cardView.next(!this.cardView.getValue());
  }

}
