import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private isConversationStarted = new BehaviorSubject<boolean>(false);
  isConversationStarted$ = this.isConversationStarted.asObservable();

  private messagesMap: { [chatId: number]: any[] } = {};
  private messageSubjects: { [chatId: number]: BehaviorSubject<any[]> } = {};
  private currentChatId: number | undefined;

  private logoClickEvent = false;
  private _logoClickEvent = new BehaviorSubject<any>(this.logoClickEvent);
  eraseEvent = this._logoClickEvent.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  // Method to update isLoading state
  setLoading(isLoading: boolean): void {
    this.isLoadingSubject.next(isLoading);
  }

  changeEraseEvent(value: boolean) {
    this._logoClickEvent.next(value)
  }

  changeStatus(isStarted: boolean) {
    this.isConversationStarted.next(isStarted);
  }

  clearChatId() {
    this.currentChatId = undefined;
    this.messagesMap = {};
    this.messageSubjects = {};
  }

  setChatId(chatId: number) {
    this.currentChatId = chatId;
    if (!this.messagesMap[chatId]) {
      this.messagesMap[chatId] = [];
    }
    if (!this.messageSubjects[chatId]) {
      this.messageSubjects[chatId] = new BehaviorSubject<any[]>(this.messagesMap[chatId] || []);
    }
  }

  getChatId(): number | undefined {
    return this.currentChatId;
  }

  addMessage(chatId: number, message: any) {
    if (!this.messagesMap[chatId]) {
      this.messagesMap[chatId] = [];
      this.messageSubjects[chatId] = new BehaviorSubject<any[]>(this.messagesMap[chatId] || []);
    }
    this.messagesMap[chatId].push(message);
    if (this.messageSubjects[chatId]) {
      (this.messageSubjects[chatId] as BehaviorSubject<any[]>).next(this.messagesMap[chatId]);
    }
  }

  setMessages(chatId: number, messages: any) {
    if (!this.messagesMap[chatId]) {
        this.messagesMap[chatId] = [];
    }
    this.messagesMap[chatId] = messages;
    if (this.messageSubjects[chatId]) {
        this.messageSubjects[chatId].next(this.messagesMap[chatId]);
      }
  }

  getMessages(chatId: number): any[] {
    return this.messagesMap[chatId] || [];
  }

  getMessagesObservable(chatId: number): BehaviorSubject<any[]> {
    if (!this.messageSubjects[chatId]) {
      this.messageSubjects[chatId] = new BehaviorSubject<any[]>(this.messagesMap[chatId] || []);
    }
    return this.messageSubjects[chatId];
  }
}
