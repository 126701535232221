import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { LocalStorageService } from '../local-storage.service';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service';
import { CookieConsentService } from 'src/app/shared/cookie-consent.service';

@Component({
    selector: 'app-chat-spyglass',
    templateUrl: './spyglass-chat.component.html',
    styleUrls: ['./spyglass-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SpyglassChatComponent implements OnInit {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    { value: 'default', name: 'All Sources', connector: 'default' }
  ];
  isEnabledCharts: boolean = false;
  isConversationStarted: boolean = false;
  messages: any[] = [];
  chatId: any = null;
  isAIProviderExists: boolean = true;
  isAIProviderLoaded: boolean = false;

  private cookieConsentInstance: any;

  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private chatService: ChatService,
    private router: Router,
    private authService: AuthService,
    private swirlService: SwirlService,
    private titleService: Title,
    private cookieConsentService: CookieConsentService
  ) {
    this.isEnabledCharts = false;
    this.isConversationStarted = false;
    this.isAIProviderExists = this.authService.getAIProviderStatus();
    this.titleService.setTitle('SWIRL AI Search Assistant');
  }

  private updateUrlWithChatId(chatId: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        chat_id: chatId
      },
      queryParamsHandling: 'merge', // merge with other query params
    });
  }

  ngOnInit(): void {
    // Initialize cookie consent banner
    this.cookieConsentService.initCookieConsent();

    // Subscribe to AIProvider status; update loaded flag once done
    this.swirlService.getIsAIProviderExistsStatus().subscribe((data) => {
      this.isAIProviderLoaded = true; // provider check complete
      if (data && data.status) {
        const exists = data.status === 'True';
        this.authService.setAIProviderStatus(exists);
        this.isAIProviderExists = exists;
      } else {
        this.isAIProviderExists = false;
      }
    });

    if (!document.body.classList.contains("dark-theme") && this.mode === 'Dark') {
      document.body.classList.toggle("dark-theme");
    }
    document.body.classList.toggle("chat");

    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light';
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
    });

    this.route.queryParams.subscribe(params => {
      this.chatId = params['chat_id'] || null;
      if (this.chatId) {
        this.chatService.setChatId(this.chatId);
        this.messages = [...this.chatService.getMessages(this.chatId)];
        this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
          this.isConversationStarted = true;
          if (messages.length == 0 && this.messages.length == 0)
            this.messages = [...this.chatService.getMessages(this.chatId)];
          if (this.messages.length < messages.length)
            this.messages = [...messages];
        });
        this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
      }
    });

    this.chatService.isConversationStarted$.subscribe((isConversationStarted) => {
      this.isConversationStarted = isConversationStarted;
      this.chatId = this.chatService.getChatId();
      this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
        if (messages.length == 0 && this.messages.length == 0)
          this.messages = [...this.chatService.getMessages(this.chatId)];
        if (this.messages.length < messages.length)
          this.messages = [...messages];
      });
      this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
    });

    this.chatService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        this.router.navigate(['/chat']);
        this.chatService.changeEraseEvent(false);
        this.isConversationStarted = false;
        this.messages = [];
        this.chatService.clearChatId();
      }
    });
  }

  ngOnDestroy(): void {
    this.cookieConsentService.destroyCookieConsent();
  }  

  handleLogoClick() {
    this.chatService.changeEraseEvent(true);
  }
}
