import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchService } from '../search.service';
import { SearchContext } from '../shared/search-context';
import { SwirlService } from '../swirl.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-sort-search',
  templateUrl: './sort-search.component.html',
  styleUrls: ['./sort-search.component.scss'],
  standalone: false,
})
export class SortSearchComponent implements OnInit {
  sortForm: UntypedFormGroup;
  searchContext: SearchContext = new SearchContext();

  // Include ConfidenceMixer by default
  sortings = [
    { value: 'RelevancyConfidenceMixer', name: 'Confidence' },
    { value: 'RelevancyMixer', name: 'Relevancy' },
    { value: 'DateMixer', name: 'Date' },
    { value: 'RoundRobinMixer', name: 'Top Pick' },
  ];

  selectedSortingName: string = ''; // Displayed name of the selected sorting
  isLoading: boolean = false;
  isShowing: boolean = false;
  numFound: number = 0;
  confidence_mixer: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private swirl: SwirlService,
    private authService: AuthService
  ) {
    // Initialize the form with the selected sorting
    const initialMixer = this.searchService.getFilter('result_mixer')?.split(':')?.[1] || 'RelevancyMixer';
    this.sortForm = this.fb.group({
      selectedSorting: [initialMixer, Validators.required],
    });

    // Get the ConfidenceMixer availability from the AuthService
    this.confidence_mixer = this.authService.getConfidenceMixerStatus();
  }

  ngOnInit(): void {
    // If ConfidenceMixer is not available, handle its presence
    if (!this.confidence_mixer) {
      // Optionally, you can disable the ConfidenceMixer option in the UI
      // Or handle it in the selectSorting method as shown below
    }

    // Get the currently selected mixer
    let selectedSortingValue = this.sortForm.get('selectedSorting')?.value;

    // If the selected mixer is ConfidenceMixer but not available, fallback to RelevancyMixer
    if (selectedSortingValue === 'RelevancyConfidenceMixer' && !this.confidence_mixer) {
      selectedSortingValue = 'RelevancyMixer';
      this.sortForm.get('selectedSorting')?.setValue(selectedSortingValue);
      this.searchService.putFilter('result_mixer', `result_mixer:${selectedSortingValue}`);
    }

    // Set the displayed name based on the selected mixer
    this.selectedSortingName =
      this.sortings.find((s) => s.value === selectedSortingValue)?.name || 'Relevancy';

    // Reorder the sortings array so that the selected sorting appears at the top
    if (selectedSortingValue) {
      this.sortings = [
        ...this.sortings.filter((s) => s.value === selectedSortingValue),
        ...this.sortings.filter((s) => s.value !== selectedSortingValue),
      ];
    }

    // Subscribe to various services to keep the UI state updated
    this.searchService.isLoading.subscribe((isLoading) => (this.isLoading = isLoading));
    this.searchService.isSearched.subscribe(
      (isSearched) => (this.isShowing = isSearched)
    );
    this.searchService.currentResults.subscribe(
      (results) => (this.numFound = results.numFound)
    );
    this.searchService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        const defaultMixer = this.sortings[0].value;
        this.searchService.putFilterWithoutSearch('result_mixer', `result_mixer:${defaultMixer}`);
        this.updateSorting(defaultMixer);
      }
    });
  }

  /**
   * Handles sorting option selection when a dropdown item is clicked.
   * Updates the selected sorting value in the form and search service.
   */
  selectSorting(value: string): void {
    // If ConfidenceMixer is selected but not available, fallback to RelevancyMixer
    if (value === 'RelevancyConfidenceMixer' && !this.confidence_mixer) {
      // Optionally, display a notification to the user
      // For example: this.notificationService.showError('Confidence sorting is not available in your version.');

      // Fallback to RelevancyMixer
      value = 'RelevancyMixer';
    }

    // Update the selectedSortingName
    this.selectedSortingName =
      this.sortings.find((s) => s.value === value)?.name || 'Relevancy';

    // Update the form control value
    this.sortForm.get('selectedSorting')?.setValue(value);

    // Reorder the sortings array so that the selected sorting appears at the top
    this.sortings = [
      ...this.sortings.filter((s) => s.value === value),
      ...this.sortings.filter((s) => s.value !== value),
    ];

    // Update the search service filter
    this.searchService.clearSelectedItems();
    if (value == null) {
      this.searchService.removeFilter('result_mixer');
    } else {
      this.searchService.putFilter('result_mixer', `result_mixer:${value}`);
    }
  }

  /**
   * Updates the sorting when an external event (e.g., reset) occurs.
   */
  updateSorting(value: string): void {
    this.selectSorting(value);
  }
}
