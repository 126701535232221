<div class="container py-3">
  <div 
    *ngFor="let message of parsedMessages" 
    class="chat-message p-3 mb-3 rounded shadow-sm"
    [ngClass]="{
      'user-input': message.role === 'user',
      'assistant-reply': message.role === 'assistant'
    }"
  >
    <!-- Message Role -->
    <span class="message-role">
      {{ message.role === 'user' ? 'You:' : 'SWIRL:' }}
    </span>

    <!-- Message Content -->
    <div class="mt-2">
      <span class="d-block" [innerHTML]="message.content"></span>

      <!-- Sources -->
      <ng-container *ngIf="message.additional_content?.sources?.length">
        <div class="sources-list">
          <p class="sources-header">Sources:</p>
          <ul class="sources-list-ul">
            <li *ngFor="let source of message.additional_content.sources" class="sources-list-li">
              <!-- Favicon -->
              <img 
                [src]="getFaviconUrl(source.url)" 
                [alt]="'Favicon for ' + source.url" 
                class="source-favicon"
                (error)="handleFaviconError($event)" 
              >
              <!-- Source Link -->
              <a 
                [href]="source.url" 
                target="_blank"
                class="sources-list-link"
              >
                <span class="me-2">{{ source.description }}</span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>

      <!-- AI Model Information -->
      <ng-container *ngIf="message.additional_content?.ai_model && message.time > 0">
        <div class="mt-2">
          <span class="ai-chat-metadata">
            AI Summary generated 
            {{ message.additional_content?.ai_model ? "by " + message.additional_content?.ai_model : "" }}
            {{ message.time > 0 ? "in " + message.time + " seconds" : "" }}.
          </span>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Dummy Element for Auto-Scroll -->
  <div #dummy></div>
</div>
