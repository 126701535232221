
/* Fixed width for user and assistant messages */
.chat-message.user-input,
.chat-message.assistant-reply {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

/* Enable text selection */
.chat-message {
  user-select: text;
}

.chat-message * {
  user-select: text;
}

/* Chatbox styles */
.user-input {
  background-color: var(--color-white);
  border: 1px solid var(--color-light-gray);
}

.assistant-reply {
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-background-blue);
}

.message-role {
  font-size: 1.25rem;
  color: var(--color-bright-blue);
}

/* Ensure scrollContainer can grow and doesn't restrict scrolling */
.container.py-3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Ensure it's scrollable */
  position: relative; /* Helps maintain layout stability */
  backface-visibility: hidden; /* Prevent rendering glitches during scrolling */
}

.ai-chat-metadata {
  font-size: 0.75rem;
  font-weight: 700;
}
