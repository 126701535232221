import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private apiUrl = '/api/swirl';
  private tokenKey = 'swirl-token';
  private chatStatusKey = 'swirl-chat-status';
  private AIProviderStatusKey = 'swirl-ai-provider-status';  // Used for Chat app status
  private promptsStatusKey = 'swirl-prompts-status';          // New key for Search app prompts
  private ConfidenceMixerStatusKey = 'swirl-confidence-mixer-status';
  private cookieConsentKey = 'swirl-cookie-consent';
  private swirlVersionKey = 'swirl-version';

  constructor(private http: HttpClient) {}

  login(username: string, password: string): any {
    const csrfToken = this.getCookie('csrftoken'); // get the CSRF token from the cookie
    const headers = new HttpHeaders().set('X-CSRFToken', csrfToken); // include the CSRF token in the request headers
    return this.http.post(`${this.apiUrl}/login/`, { username, password }, { headers }).pipe(
      tap((response: any) => {
        // Store token
        this.setToken(response.token);

        // Store cookie consent flag
        if (response.cookie_consent) {
          this.setCookieConsent(response.cookie_consent === 'True');
        }

        // Store the SWIRL version
        if (response.swirl_version) {
        this.setSwirlVersion(response.swirl_version);
        }
      })
    );
  }

  // Method to get cookie consent status
  getCookieConsent(): boolean {
    const consent = localStorage.getItem(this.cookieConsentKey);
    return consent === 'true'; // Ensure boolean conversion
  }

  // Method to set cookie consent status
  setCookieConsent(status: boolean): void {
    localStorage.setItem(this.cookieConsentKey, status.toString());
  }

  getSwirlVersion(): string | null {
    return localStorage.getItem(this.swirlVersionKey);
  }

  setSwirlVersion(version: string): void {
    localStorage.setItem(this.swirlVersionKey, version);
  }

  oidcLogin(token: string): any {
    const csrfToken = this.getCookie('csrftoken');
    const headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken)
      .set('OIDC-Token', `Bearer ${token}`);
  
    return this.http.post(`${this.apiUrl}/oidc_authenticate/`, {}, { headers }).pipe(
      tap((response: any) => {
        // Store token
        this.setToken(response.token);
  
        // Store cookie consent flag
        if (response.cookie_consent) {
          this.setCookieConsent(response.cookie_consent === 'True');
        }
  
        // Store the SWIRL version
        if (response.swirl_version) {
          this.setSwirlVersion(response.swirl_version);
        }
      })
    );
  }

  logout(): any {
    const csrfToken = this.getCookie('csrftoken');
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken)
      .set('Authorization', 'Token ' + this.getToken())
      .set('Content-Type', 'application/json');
    return this.http.post(`${this.apiUrl}/swirl-logout/`, {}, { headers });
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  getChatStatus(): boolean {
    return localStorage.getItem(this.chatStatusKey) === 'true';
  }

  setChatStatus(status: boolean): void {
    localStorage.setItem(this.chatStatusKey, status.toString());
  }

  // --- AIProvider Status (for Chat) ---
  getAIProviderStatus(): boolean {
    return localStorage.getItem(this.AIProviderStatusKey) === 'true';
  }

  setAIProviderStatus(status: boolean): void {
    localStorage.setItem(this.AIProviderStatusKey, status.toString());
  }

  // --- Prompts Status (for Search) ---
  // Note: This now uses a separate local storage key.
  getPromptsStatus(): boolean {
    return localStorage.getItem(this.promptsStatusKey) === 'true';
  }

  setPromptsStatus(status: boolean): void {
    localStorage.setItem(this.promptsStatusKey, status.toString());
  }

  getConfidenceMixerStatus(): boolean {
    return localStorage.getItem(this.ConfidenceMixerStatusKey) === 'true';
  }

  setConfidenceMixerStatus(status: boolean): void {
    localStorage.setItem(this.ConfidenceMixerStatusKey, status.toString());
  }

  getUser(): string | null {
    return localStorage.getItem('user');
  }

  setUser(user: string): void {
    localStorage.setItem('user', user);
  }

  getCookie(name: string): string {
    let cookieValue = '';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  setCookie(name: string, value: string, minutes: number): void {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/;`;
  }

  redirectToAuth(idp: string) {
    const token = this.getToken();
    if (token) {
      const path = window.location.pathname + window.location.search;
      this.setCookie('Token', token, 20);
      this.setCookie('Callback-Path', path, 20);
      window.location.href = `/api/swirl/${idp.toLowerCase()}-idp-auth`;
    }
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return !!token;
  }
}
