  <!-- Provider -->
  <span *ngIf="provider" class="provider">
    {{ provider }}
  </span>

  <!-- URL -->
  <span *ngIf="url" class="url">
    <a href="{{url}}" target="_blank">{{url}}</a>
  </span>

  <!-- Fallback -->
  <span *ngIf="!provider && !url" class="text-muted">
    <em>No authorship information available.</em>
  </span>

