<div class="w-100 d-flex flex-column min-vh-100">
  <!-- Navbar Section -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
    <div class="container-fluid">
      <div class="row w-100 align-items-start gx-0">
        <!-- Logo Column -->
        <div class="col-md-3 col-lg-2 d-flex justify-content-center align-items-center pt-4 me-3">
          <a
            class="navbar-brand"
            href="#"
            (click)="handleLogoClick()"
          >
            <img 
              src="/api/swirl/sapi/branding/?target=light"
              alt="SWIRL logo" 
              class="logo-img"
            />
          </a>
        </div>
  
        <!-- Search Box Column -->
        <div class="col-md-8 col-lg-9 ms-3 pt-4">
          <app-box [promptsExists]="promptsExists"></app-box>
        </div>
  
        <!-- Profile Icon Column -->
        <div class="col-auto d-flex justify-content-end pt-4 pe-4 ms-auto">
          <profile-box></profile-box>
        </div>
      </div>
    </div>
  </nav>

  <!-- Main Content Section -->
  <div class="container-fluid px-3 flex-grow-1 mt-3">
    <!-- Chart Section - user option -->
    <div *ngIf="isEnabledCharts && !isLoading" class="mb-3">
      <charts-container></charts-container>
    </div>

    <!-- Results Section -->
    <div class="row align-items-start">
      <!-- Sources Column -->
      <div class="col-md-3 col-lg-2 sources-column me-3" *ngIf="hasFacets">
        <app-facet-nav></app-facet-nav>
      </div>
  
      <!-- Results Column -->
      <div class="col-md-8 col-lg-9 results-column ms-3 p-0">
        <div class="row gx-0">
          <app-results></app-results>
        </div>
      </div>
  
      <!-- Right Spacer Column -->
      <div class="d-none d-lg-block col-auto ms-auto">
        <!-- Empty for spacing -->
      </div>
    </div>
  </div>
</div>
