<div *ngIf="!isLoading && result.numRetrieved > searchContext.rows" class="d-flex align-items-center justify-content-center my-0">
  <nav aria-label="Pagination navigation">
    <ul class="pagination m-0">
      <li class="page-item" [class.disabled]="page === 1">
        <button class="page-link" (click)="changePage(page - 1)" aria-label="Previous">Prev</button>
      </li>
      <li class="page-item" *ngFor="let p of getPages()" [class.active]="p === page">
        <button class="page-link" (click)="changePage(p)">{{ p }}</button>
      </li>
      <li class="page-item" [class.disabled]="page === getLastPage()">
        <button class="page-link" (click)="changePage(page + 1)" aria-label="Next">Next</button>
      </li>
    </ul>
  </nav>
</div>
