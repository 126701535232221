import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Result } from '../shared/result';
import { SearchService } from '../search.service';
import { SearchContext } from '../shared/search-context';
import { ConfigService } from '../config.service';
import { ConfigResponse, ResultCard } from '../shared/config-response';
import { ResultMapping } from '../shared/result-mapping';
import { ColumnMapping } from '../shared/column-mapping';
import { SwirlService } from '../swirl.service';
import { firstValueFrom } from 'rxjs';


@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
    standalone: false
})
export class ResultsComponent implements OnInit {

  result: Result;
  globalShowDetails: boolean = false;
  searchContext: SearchContext;
  resultMapping: ResultMapping;
  columnMapping: Array<ColumnMapping>;
  resultType: string;
  resultLayout: ResultCard;
  highlights: any;
  page: number;
  isLoading: boolean;
  isShowing: boolean;
  isCardView: boolean;
  config: ConfigResponse;
  isDateFilter: boolean;
  checkedSelectItems: boolean;
  selectItemsLabel: string = 'Select Items';
  previouslySelectedItems: any[] = [];
  isDeselected: boolean = false;

  constructor(private searchService: SearchService, private configService: ConfigService, private location: Location, private swirl: SwirlService) {
    this.result = new Result();
    this.searchContext = new SearchContext();
    this.resultMapping = new ResultMapping();
    this.resultType = '';
    this.resultLayout = new ResultCard();
    this.columnMapping = [];
    this.highlights = {};
    this.page = 0;
    this.isLoading = false;
    this.isShowing = false;
    this.isCardView = false;
    this.config = new ConfigResponse();
    this.isDateFilter = false;
    this.checkedSelectItems = false;
  }

  ngOnInit() {
    this.location.subscribe((event) => {
      if (!event.url || event.url === '')
        this.searchService.changeEraseEvent(true)
    });
    this.configService.getConfig().subscribe(configResponse => {
      if (configResponse) {
        this.config = configResponse;
        if (configResponse['resultType']) {
          this.resultType = configResponse['resultType'];
        }
        if (configResponse.resultLayout) {
          this.resultLayout = configResponse.resultLayout;
        }
        if (configResponse.resultColumns) {
          this.columnMapping = configResponse.resultColumns;
        }
        if (configResponse.resultFields) {
          this.resultMapping = configResponse.resultFields;
        }
      }

    });
    // Subscribe to results
    this.searchService.currentResults.subscribe(async (result) => {
      const isSearched = await firstValueFrom(this.searchService.isSearched);

      // Ensure isShowing reflects the correct state
      this.isShowing = isSearched;
      this.result = result || { docs: [] }; // Ensure result.docs is always an array
    });
    this.searchService.currentSearchContext.subscribe(searchContext => {
      this.searchContext = searchContext;
      this.page = (searchContext.start / searchContext.rows) + 1;
    });
    this.searchService.currentHighlights.subscribe(highlights => {
      this.highlights = highlights;
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        this.isLoading = true;
        this.searchService.selectedItems.next(false)
        this.checkedSelectItems = false
      }
    })
    this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    this.searchService.isCardView.subscribe(isCardView => this.isCardView = isCardView);
    this.searchService.isNewSearch.subscribe(isNewSearch => this.isShowing = false)
    this.searchService.resultMixer.subscribe(resultMixer => this.isDateFilter = resultMixer === 'DateMixer')
    this.searchService.isNewSearch.subscribe(isNewSearch => {
      this.checkedSelectItems = !isNewSearch
      this.searchService.selectedItems.next(false)
      this.searchService.clearSelectedItems()
    })
    this.swirl.getBrandingValues('config').subscribe((values) => {
      this.selectItemsLabel = values.select_items_label ?? this.selectItemsLabel
    });
  }

  toggleAllDetails(): void {
    this.globalShowDetails = !this.globalShowDetails; // Toggle state
  }

  toggleChange() {
    this.checkedSelectItems = !this.checkedSelectItems;
    this.searchService.cleanAllToggle.next(false);
  
    if (this.checkedSelectItems) {
      // Reactivating selection: ensure any prior "deselected" state is cleared
      this.isDeselected = false;
      for (const doc of this.result.docs) {
        this.searchService.addToSelectedItems(doc['swirl_id'], false, doc['swirl_score']);
      }
    } else {
      this.searchService.clearSelectedItems();
      this.searchService.cleanAllToggle.next(true);
      this.isDeselected = false;
    }
    this.searchService.selectedItems.next(this.checkedSelectItems);
  }

  toggleSelectDeselect() {
    if (!this.isDeselected) {
      // "Deselect All" action:
      // Save the current selection before clearing it.
      this.previouslySelectedItems = [...this.searchService._selectedRagItems.value];
      this.searchService.clearSelectedItems();
      this.searchService.cleanAllToggle.next(true);
      this.isDeselected = true;
    } else {
      // "Select All" action:
      // Reapply the previously stored selection by directly updating the BehaviorSubjects.
      const reselected = [...this.previouslySelectedItems];
      this.searchService._selectedRagItems.next(reselected);
      // Build a new _ragItems object with each item set to true.
      const newRagItems = reselected.reduce((acc: { [key: string]: boolean }, itemId: any) => {
        acc[itemId] = true;
        return acc;
      }, {});
      this.searchService._ragItems.next(newRagItems);
      // Also update the state used by the "Select Items" toggle.
      this.checkedSelectItems = true;
      this.searchService.selectedItems.next(true);
      this.searchService.cleanAllToggle.next(false);
      this.isDeselected = false;
    }
  }
}
