<div class="row justify-content-between">
  <ng-template [ngIf]="result_url" [ngIfElse]="no_url">
    <a [href]="result_url" target="_blank" class="col title content" [innerHTML]="result_title"></a>
  </ng-template>
  <ng-template #no_url>
    <span class="col title content" [innerHTML]="result_title"></span>
  </ng-template>
  <ng-template [ngIf]="date">
    <span class="col-auto date content pub-date text-end">
      <span class="label">Publication Date: </span>
      <span class="value">{{ date }}</span>
    </span>
  </ng-template>
</div>
