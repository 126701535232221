import { Component, OnInit } from '@angular/core';
import { Microsoft } from '../../auth/microsoft.service'
import { Location } from '@angular/common';

@Component({
    selector: 'microsoft-callback',
    templateUrl: './microsoft-callback.component.html',
    standalone: false
})
export class MicrosoftCallbackComponent implements OnInit {

  constructor(private location: Location, private microsoftAuth: Microsoft) { }

  async ngOnInit(): Promise<void> {
    this.microsoftAuth.login()
  }

}