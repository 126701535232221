import { Microsoft } from './microsoft.service'

// New Authenticator: Add this list
export const ConnectorsAuthenticators = {
    M365OutlookMessages: "Microsoft",
    M365OneDrive: "Microsoft",
    M365OutlookCalendar: "Microsoft",
    M365SharePointSites: "Microsoft",
    MicrosoftTeams: "Microsoft"
}

// New Authenticator: Add this list
export const Authenticators = [
    "Microsoft"
]

// New Authenticator: Add this list
export const AuthenticatorsObject: any = {
    "Microsoft": Microsoft
}