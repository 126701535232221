import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchService } from '../search.service';
import { SearchContext } from '../shared/search-context';
import { SwirlService } from '../swirl.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WebSocketService } from '../websockets.service';
import { AuthService } from '../../auth/auth.service';

const RESULT_MIXER_KEY = 'result_mixer';
const DEFAULT_MIXER = 'result_mixer:RelevancyConfidenceMixer';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  standalone: false,
})
export class BoxComponent implements OnInit, OnDestroy {
  @Input() promptsExists: boolean = false;

  searchForm: UntypedFormGroup;
  searchContext: SearchContext = new SearchContext();

  sources: { value: string; name: string; connector: string; selected: boolean }[] = [
    { value: 'default', name: 'All Sources', connector: 'default', selected: true }
  ];

  selectedSources: FormControl<string[]> = new FormControl<string[]>(['default'], { nonNullable: true });
  previousSelectedSources: string[] = ['default'];

  // Initialize prompts array without the 'reset' entry
  prompts: { value: string; name: string }[] = [];

  hasPrompts: boolean = false;

  currentRagPrompt: string = '';

  dataReady: boolean = false;

  hasResults: boolean = false;

  previousParams: { q: string, providers: string, rag: string, rag_timeout: string, prompt: string } = {
    q: '',
    providers: '',
    rag: '',
    rag_timeout: '',
    prompt: ''
  };

  checkedGenerateAiResponse: boolean = false;
  isRAGButtonDisabled: boolean = false;

  searchButtonLabel: string = 'SEARCH';
  searchBarPlaceholder: string = 'What are you searching for today?';
  aiResponseLabel: string = 'Generate AI Response';

  isLoading: boolean = false; // For Search operations
  isRagLoading: boolean = false; // For RAG operations

  // Variable to store the default prompt value
  defaultPromptValue: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private swirl: SwirlService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private webSocketService: WebSocketService
  ) {
    this.searchForm = this.fb.group({
      query: [{ value: '', disabled: this.isLoading }], // Initially disabled if isLoading is true
      checkedGenerateAiResponse: [false],
      selectedSources: this.selectedSources,
      selectedPrompt: [''] // Initialize without default value
    });
  }

  get isSearchButtonDisabled(): boolean {
    return this.searchForm.get('query')?.value?.trim() === '';
  }

  ngOnInit() {
    this.swirl.getSources().subscribe(async response => {
      response
        .filter((source: any) => source.active)
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .forEach((source: any) => {
          this.sources.push({
            value: source.id.toString(),
            name: source.name,
            connector: source.connector,
            selected: false
          });
        });

      if (this.authService.getPromptsStatus()) { // Only fetch prompts if they are supported
        this.swirl.getPrompts().subscribe(response => {
          const searchRagDefaultPrompt = response.find((prompt: any) => prompt.name === 'search_rag_default');
          const chatRagDefaultPrompt = response.find((prompt: any) => prompt.name === 'chat_rag_default');

          if (!searchRagDefaultPrompt) console.error('Default prompt "search_rag_default" not found.');
          if (!chatRagDefaultPrompt) console.error('Default prompt "chat_rag_default" not found.');

          // Add "Swirl Rag Default" as the first prompt
          if (searchRagDefaultPrompt) {
            this.prompts.push({
              value: searchRagDefaultPrompt.id.toString(),
              name: 'Swirl Rag Default'
            });
            this.defaultPromptValue = searchRagDefaultPrompt.id.toString(); // Set as default
          }

          // Add "Chat Rag Default" as the second prompt
          if (chatRagDefaultPrompt) {
            this.prompts.push({
              value: chatRagDefaultPrompt.id.toString(),
              name: 'Chat Rag Default'
            });
          }

          // Add other prompts with 'rag' tag, excluding the default ones
          const filteredPrompts = response.filter(
            (prompt: any) =>
              prompt.tags.some((tag: any) => tag.includes('rag')) &&
              prompt.name !== 'search_rag_default' &&
              prompt.name !== 'chat_rag_default'
          );

          filteredPrompts.forEach((prompt: any) => {
            this.prompts.push({
              value: prompt.id.toString(),
              name: this.formatPromptName(prompt.name),
            });
          });

          // Determine if prompts exist and update both local and input-bound flags.
          this.hasPrompts = this.prompts.length > 0;
          this.promptsExists = this.hasPrompts; // Synchronize the input with actual data

          // Once sources and prompts are ready
          this.dataReady = true;

          // Conditional default prompt setting
          const currentSelectedPrompt = this.searchForm.get('selectedPrompt')?.value;
          if (!currentSelectedPrompt && this.defaultPromptValue) {
            this.searchForm.get('selectedPrompt')?.setValue(this.defaultPromptValue);
          }
        });
      } else {
        // If no prompts are needed, update both flags accordingly.
        this.hasPrompts = false;
        this.promptsExists = false;
        this.dataReady = true;
      }

      // Subscribe to current search context
      this.searchService.currentSearchContext.subscribe(searchContext => {
        this.searchContext = searchContext;
        // Reset the loading state since search is complete
        this.setSearchLoadingState(false);
      });

      // Handle query parameters for search
      this.route.queryParamMap.subscribe(params => {
        const state = this.router.getCurrentNavigation()?.extras.state;
        const q = params.get('q');
        const providers = params.get('providers');
        const rag = params.get('rag');
        const rag_timeout = params.get('rag_timeout');
        let promptParam = params.get('prompt');

        // If this is a RAG request and the prompt parameter is missing or empty,
        // substitute the stored prompt (or the default).
        if (rag === 'true' && (!promptParam || promptParam.trim() === '')) {
          promptParam = this.currentRagPrompt || this.defaultPromptValue;
          const updatedQueryParams = { q, providers, rag, rag_timeout, prompt: promptParam };
          this.router.navigate([], { queryParams: updatedQueryParams, replaceUrl: true });
        }

        const paramsObject = { q, providers, rag, rag_timeout, prompt: promptParam };

        if (state?.rerun) {
          this.previousParams = {
            q: q || '',
            providers: providers || '',
            rag: rag || '',
            rag_timeout: rag_timeout || '',
            prompt: promptParam || ''
          };
          this.makeSearch(paramsObject);
          this.router.navigate(['/'], { queryParams: paramsObject });
        } else {
          if (!this.compareObjects(paramsObject, this.previousParams))
            this.makeSearch(paramsObject);
        }
      });

      // Handle erase event
      this.searchService.eraseEvent.subscribe((isErased) => {
        if (isErased) {
          this.router.navigate(['/']);
          this.searchService.changeEraseEvent(false);
          this.searchForm.get('checkedGenerateAiResponse')?.patchValue(false);
          this.searchForm.get('selectedPrompt')?.setValue(this.defaultPromptValue);
          // this.isSearchButtonDisabled = false;
          this.isRAGButtonDisabled = false;
        }
      });

      this.searchService.currentResults.subscribe(results => {
        // Ensure results.docs is an array; if not, default to false.
        this.hasResults = results && results.docs && results.docs.length > 0;
      });
    });

    // Fetch branding values
    this.swirl.getBrandingValues('config').subscribe((values) => {
      this.searchButtonLabel = values.search_button_label ?? this.searchButtonLabel;
      this.searchBarPlaceholder = values.search_bar_placeholder ?? this.searchBarPlaceholder;
      this.aiResponseLabel = values.ai_response_label ?? this.aiResponseLabel;
    });

    // Subscribe to WebSocket loading state
    this.searchService.isWebsocketLoading.subscribe((isWebsocketLoading) => {
      // this.isSearchButtonDisabled = isWebsocketLoading;
      this.setRagLoadingState(isWebsocketLoading);
      if (!isWebsocketLoading)
        this.searchForm.get('checkedGenerateAiResponse')?.patchValue(false);
    });

    // Subscribe to search loading state
    this.searchService.isLoading.subscribe((isLoading) => {
      this.setSearchLoadingState(isLoading);
      const checkedGenerateAiResponseControl = this.searchForm.get('checkedGenerateAiResponse');
      if (isLoading && checkedGenerateAiResponseControl) {
        checkedGenerateAiResponseControl.disable();
      } else if (checkedGenerateAiResponseControl) {
        checkedGenerateAiResponseControl.enable();
      }
    });
  }

  ngOnDestroy() {
    // No subscriptions to clean up related to ResetService
  }

  /**
   * Getter to determine the display name of the selected prompt.
   * Displays the actual prompt name.
   */
  get selectedPromptName(): string {
    const selectedValue = this.searchForm.get('selectedPrompt')?.value;
    const selected = this.prompts.find(p => p.value === selectedValue);
    return selected ? selected.name : 'Select Prompt';
  }

  /**
   * Helper method to get the effective prompt value.
   * Returns the selected prompt's value.
   */
  private getEffectivePromptValue(): string | undefined {
    const selectedPromptValue = this.searchForm.get('selectedPrompt')?.value;
    return selectedPromptValue;
  }

  /**
   * Manages the search loading state by enabling/disabling the query control.
   */
  setSearchLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
  
    const queryControl = this.searchForm.get('query');
    if (isLoading) {
      queryControl?.disable(); // Disable the control when loading
    } else {
      queryControl?.enable(); // Enable the control when not loading
    }
  }

  /**
   * Manages the RAG loading state.
   */
  setRagLoadingState(isLoading: boolean): void {
    this.isRagLoading = isLoading;
  }

  /**
   * Handles prompt selection from the dropdown.
   */
  selectPrompt(value: string): void {
    this.searchForm.get('selectedPrompt')?.setValue(value);
    const effectivePrompt = this.getEffectivePromptValue();

    const queryParams = { ...this.route.snapshot.queryParams };
    if (effectivePrompt) {
      queryParams['prompt'] = effectivePrompt;
    } else {
      delete queryParams['prompt'];
    }

    this.router.navigate([], { queryParams });
  }

  /**
   * Getter for the checkedGenerateAiResponse control.
   */
  get checkedGenerateAiResponseControl(): FormControl | null {
    const control = this.searchForm.get('checkedGenerateAiResponse');
    if (control instanceof FormControl) {
      return control;
    }
    console.error('Error: checkedGenerateAiResponse is not a FormControl');
    return null;
  }

  /**
   * Returns the concatenated names of selected sources.
   */
  get selectedSourcesNames(): string {
    const selectedSources = this.sources
      .filter((source) => source.selected)
      .map((source) => source.name);
    return selectedSources.length > 0 ? selectedSources.join(', ') : 'All Sources';
  }

  /**
   * Initiates the search based on provided parameters.
   */
  makeSearch(params: any) {
    const q = params['q'];
    if (q) {
      // **Set the 'query' form control immediately**
      this.searchForm.get('query')?.setValue(q);
      
      const rag = params['rag'];
      const rawPrompt = params['prompt'];
      this.searchForm.get('selectedPrompt')?.setValue(rawPrompt || this.defaultPromptValue);
      
      let prompt = this.getEffectivePromptValue();

      if (rag) {
        this.webSocketService.changeStatus(true, true, [], prompt);
        this.searchForm.get('checkedGenerateAiResponse')?.patchValue(true);
      }

      // The display is handled by the getter

      const providers = params['providers'];
      let parsedProviders: string[] = [];
      if (providers?.includes(',')) {
        parsedProviders = providers.split(',');
        this.selectedSources.setValue(parsedProviders.map((p: any) => p.toString()));
      } else if (providers) {
        this.selectedSources.setValue([providers.toString()]);
      } else {
        this.selectedSources.setValue(['default']);
      }

       // **Synchronize `this.sources` with `selectedSources`**
      this.syncSelectedSources();

      const sourceConnectorName = this.sources
        .filter(s => this.selectedSources.value.includes(s.value))
        .map(s => s?.connector) || [];
      const allSourceConnectorNames = this.sources.map(s => s.connector);
      const isDefault = this.selectedSources.value.length === 1 && this.selectedSources.value[0] === 'default';
      const sourceConnectorNames = [...new Set(isDefault ? allSourceConnectorNames : sourceConnectorName)];
      const theMixer = this.searchContext.filters.get(RESULT_MIXER_KEY) || DEFAULT_MIXER;
      this.searchService.putFilterWithoutSearch(RESULT_MIXER_KEY, theMixer);
      this.searchService.changeQueryAndProviders(q as string, isDefault ? [] : this.selectedSources.value, sourceConnectorNames);
    } 
    else {
      this.selectedSources.setValue(['default']);
      this.searchForm.get('query')?.patchValue('');

      // **Synchronize `this.sources` with `selectedSources`**
      this.syncSelectedSources();
    }

    setTimeout(() => {
      this.previousParams = {
        q: q || '',
        providers: params['providers'] || '',
        rag: params['rag'] || '',
        rag_timeout: params['rag_timeout'] || '',
        prompt: params['prompt'] || ''
      };
    }, 100);
  }

  /**
   * Synchronizes the 'selected' property of each source in 'this.sources' with the 'selectedSources' FormControl value.
   */
  private syncSelectedSources(): void {
    const selectedValues = this.selectedSources.value;
    this.sources.forEach(source => {
      source.selected = selectedValues.includes(source.value);
    });
  }
  
  /**
   * Compares two objects, excluding 'rag' and 'prompt' keys.
   */
  compareObjects(obj1: any, obj2: any) {
    for (let key in obj1) {
      if (key === 'rag' || key === 'prompt') continue;
      if ((obj1?.[key] || '') !== (obj2?.[key] || '')) {
        return false;
      }
    }
    for (let key in obj2) {
      if (key === 'rag' || key === 'prompt') continue;
      if ((obj1?.[key] || '') !== (obj2?.[key] || '')) {
        return false;
      }
    }
    return true;
  }

  /**
   * Handles toggling of source selections.
   */
  onSourceToggle(source: { value: string; name: string; connector: string; selected: boolean }): void {
    source.selected = !source.selected;

    if (source.value === 'default') {
      this.sources.forEach((s) => (s.selected = s.value === 'default' ? source.selected : false));
    } else {
      const allSources = this.sources.find((s) => s.value === 'default');
      if (allSources) {
        allSources.selected = this.sources.every((s) => s.value === 'default' || s.selected);
      }
    }

    const selectedValues = this.sources.filter((s) => s.selected).map((s) => s.value);
    this.selectedSources.setValue(selectedValues);
  }

  /**
   * Handles form submission to initiate a search.
   */
  onSubmit() {
    const currentQuery = this.searchForm.value?.query?.trim();

    if (currentQuery && currentQuery.length > 0) {
      // Prepare query parameters
      const queryParams: { [key: string]: any } = {
        q: currentQuery,
        timestamp: Date.now(), // Force navigation even if the query hasn't changed
      };

      // Check if non-default sources are selected
      if (!this.selectedSources.value.includes('default')) {
        const providers = this.selectedSources.value.join(',');
        queryParams['providers'] = providers;
      }

      // Add RAG options if enabled
      if (this.searchForm.value.checkedGenerateAiResponse) {
        queryParams['rag'] = 'true';
        const effectivePrompt = this.getEffectivePromptValue() || this.defaultPromptValue;
        queryParams['prompt'] = effectivePrompt;
        // Store the effective prompt so follow-up searches can reuse it.
        this.currentRagPrompt = effectivePrompt;
      }

      // Set the loading state to true
      this.setSearchLoadingState(true);

      // Update `previousParams` to reflect the latest search
      this.previousParams = {
        q: currentQuery,
        providers: queryParams['providers'] || '',
        rag: queryParams['rag'] || '',
        rag_timeout: queryParams['rag_timeout'] || '',
        prompt: queryParams['prompt'] || '',
      };

      // Navigate with updated query parameters (URL now includes a prompt param)
      this.router.navigate(['/'], { queryParams, state: { rerun: true } });
    }
  }

  /**
   * Handles changes to the "Generate AI Response" toggle.
   */
  toggleChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.searchForm.get('checkedGenerateAiResponse')?.patchValue(isChecked);

    const queryParams = { ...this.route.snapshot.queryParams };

    if (!isChecked) {
      this.webSocketService.send('stop');
      this.searchService.websocketLoading.next(false);
      delete queryParams['rag'];
      delete queryParams['prompt'];
    } else {
      queryParams['rag'] = 'true';
      const prompt = this.getEffectivePromptValue() || this.defaultPromptValue;
      queryParams['prompt'] = prompt;
      // Store the prompt for follow-up searches.
      this.currentRagPrompt = prompt;

      const providersIds = this.searchService.getRefine().map((r: any) => r['value']);
      this.webSocketService.changeStatus(isChecked, false, providersIds, prompt);
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: '',
    });
  }

  /**
   * Formats prompt names by replacing underscores with spaces and capitalizing words.
   */
  formatPromptName(name: string): string {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  /**
   * Resets the form to default values.
   */
  resetToDefault() {
    this.searchForm.reset({
      query: '',
      checkedGenerateAiResponse: false,
      selectedSources: ['default'],
      selectedPrompt: this.defaultPromptValue // Reset to default prompt
    });

    const queryParams: any = {
      q: '',
      providers: 'default',
      prompt: this.defaultPromptValue // Ensure default prompt is reflected in query parameters
    };

    this.router.navigate([], {
      queryParams: queryParams,
      replaceUrl: true
    });

    // this.isSearchButtonDisabled = false;
    this.isRAGButtonDisabled = false;
    this.webSocketService.send('stop');
    this.searchService.websocketLoading.next(false);
  }
}
