<div *ngIf="!isLoading && isActive" class="w-100">
  <div class="list-group">
    <!-- Header for Facet -->
    <div class="list-group-item facet-header">
      SOURCES
    </div>
    <!-- Facet Component -->
    <app-facet facetKey="Source" class="facet-box"></app-facet>
  </div>
</div>
