import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigResponse } from './shared/config-response';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private scopeSource = new BehaviorSubject<string | null>(null);
  currentScope = this.scopeSource.asObservable();

  private configSource: Observable<ConfigResponse | null>;

  constructor(private http: HttpClient) {
    this.configSource = this.currentScope.pipe(
      switchMap((scope) => {
        if (scope) {
          return this.http.get<ConfigResponse>('/api/config/' + scope);
        } else {
          return of(null);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getConfig(scope?: string | null): Observable<ConfigResponse | null> {
    if (scope && scope !== this.scopeSource.value) {
      this.scopeSource.next(scope);
    }
    return this.configSource;
  }
}
