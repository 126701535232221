import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.authService.logout().subscribe(
            () => {
              sessionStorage.clear();
              localStorage.clear();
              this.reloadPage();
            },
            () => {
              sessionStorage.clear();
              localStorage.clear();
              this.reloadPage();
            }
          );
        }
        return throwError(() => error);
      })
    );
  }

  // Extracted reload functionality for easier testing.
  protected reloadPage(): void {
    window.location.reload();
  }
}
