/* Uncomment to make the navbar remain fixed
nav {
  position: sticky;
  top: 0;
  z-index: 10;
}
*/

main {
  flex: 1; /* Take all available space between the navbar and footer */
  overflow-y: auto; /* Enable scrolling for overflowing content */
}

footer {
  position: sticky; /* Ensure footer stays at the bottom */
  bottom: 0;
  z-index: 10; /* Ensure it's above content if necessary */
}

/* Wrapper for consistent width and centering */
.chat-box-wrapper {
  max-width: 1000px; /* Set the desired max-width */
  width: 100%;
}

/* The actual chat-box styling */
.chat-box {
  width: 100%; /* Inherit width from the wrapper */
}

/* Override hit-highlighting display */
em {
  font-style: normal; /* Remove italics */
  font-weight: 700;   /* Apply bold */
}
