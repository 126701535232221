import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../spyglass-search/config.service'
import { OauthService } from '../auth/oauth2-service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  error_message: string = '';
  is_oauth: boolean = false;
  forgotPasswordLink: string = '';
  fromChat: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private oauthService: OauthService,
    private configService: ConfigService,
    private titleService: Title,
    private route: ActivatedRoute,
  ) {
    this.titleService.setTitle('SWIRL AI Search');
  }

  ngOnInit() {
    if (localStorage.getItem('isDark') === 'Dark')
      document.body.classList.toggle('dark-theme');

    this.route.queryParams.subscribe(params => {
      this.fromChat = params['fromComponent'] === 'chat';
    });
  
    this.configService.getConfig('default').subscribe((configResponse) => {
      if (configResponse) {
          const clientId = configResponse.oauthConfig?.clientId;
          this.is_oauth = !!clientId && clientId !== '<app-id>';
          if (configResponse.swirlBaseURL) {
              this.forgotPasswordLink = configResponse.swirlBaseURL + '/password_reset';
          }
      }
    });  
  }

  login() {
    this.error_message = ''

    this.authService.login(this.username, this.password).subscribe(
      (data: any) => {
        if(data.token) {
          this.authService.setToken(data.token)
          this.authService.setUser(data.user)
          if(data.chat_view) {
            this.authService.setChatStatus(data.chat_view == 'True')
          }
          if(data.ai_provider_exists) {
            this.authService.setAIProviderStatus(data.ai_provider_exists == 'True')
          }
          if(data.prompts_exists) {
            this.authService.setPromptsStatus(data.prompts_exists == 'True')
          }
          if(data.confidence_mixer) {
            this.authService.setConfidenceMixerStatus(data.confidence_mixer == 'True')
          }
          if (this.fromChat && this.authService.getChatStatus()) {
            this.router.navigate(['/chat']);
          } else {
            this.router.navigate(['/']);
          }
        }
        else if(data.error) {
          console.log(data.error)
          this.error_message = 'Your Username OR Password is incorrect.'
        }
      },
      (error: any) => {
        if (error.status == "403") {
          this.router.navigate(["/lockout"]);
        } else {
          console.log(error);
        }
      }
    );
  }

  oidcLogin() {
    // Store the redirection flag in local storage
    const fromChat = this.route.snapshot.queryParamMap.get('fromComponent');
    if (fromChat === 'chat') {
      localStorage.setItem('fromComponent', 'chat');
    } else {
      localStorage.removeItem('fromComponent');
    }
    this.oauthService.initCodeFlow();
  }
}
