<div class="row info-container">
  <div class="col-auto" *ngIf="error && !isLoading">
    <span class="error-text">{{ error.msg }}</span>
  </div>

  <div class="col d-flex align-items-center" *ngIf="qTime && !isLoading && numRetrieved > 0">
    <!-- Results Messaging -->
    <p class="m-0" *ngIf="(start + rows) < numRetrieved">
      Showing results {{start + 1}} - {{start + rows}} of {{ numRetrieved | number}} retrieved of {{numFound | number}} found in {{qTimeSeconds}} seconds.
    </p>
    <p class="m-0" *ngIf="(start + rows) >= numRetrieved">
      Showing results {{start + 1}} - {{ numRetrieved | number}} of {{ numRetrieved | number}} retrieved of {{numFound | number}} found in {{qTimeSeconds}} seconds.
    </p>
  </div>
</div>
