import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../spyglass-search/config.service';

@Component({
  selector: 'app-pre-chat',
  standalone: false,
  templateUrl: './pre-chat.component.html',
  styleUrls: ['./pre-chat.component.scss']
})
export class PreChatComponent implements OnInit {

swirlDocOrigin: string = ''
  constructor(private router: Router, private configService: ConfigService) {}

  ngOnInit(): void {
    window.addEventListener('message', this.handleMessage.bind(this));
    this.configService.getConfig('default').subscribe(configResponse => {
        if(configResponse) {
            if(configResponse.swirlDocOrigin)
              this.swirlDocOrigin = configResponse.swirlDocOrigin
        }
      })
  }

  private handleMessage(event: MessageEvent): void {
    console.log('Received message from origin:', event.origin, 'data:', event.data);

    if (event.origin !== this.swirlDocOrigin) {
      console.warn('Ignored message from untrusted origin:', event.origin);
      return;
    }

    if (event.data && event.data.action === 'setLocalStorage') {
      console.log('Setting local storage values in the iframe.');
      const payload = event.data.payload;

      if (payload && typeof payload === 'object') {
        for (const [key, value] of Object.entries(payload)) {
          localStorage.setItem(key, String(value));
          console.log(`Set localStorage item: ${key} = ${String(value)}`);
        }

        // Check if a csrftoken is present and set it as a cookie
        if (payload.hasOwnProperty('csrftoken')) {
          // Set the cookie for the current domain (the iframe's domain)
          document.cookie = `csrftoken=${encodeURIComponent(payload.csrftoken)}; path=/; SameSite=None; Secure`;
          console.log(`Set csrftoken cookie: ${payload.csrftoken}`);
        }

        console.log('Local storage successfully updated.');
        // After setting localStorage and cookie, navigate to the chat page
        this.router.navigate(['/chat']);
      } else {
        console.error('Payload is missing or not an object:', payload);
      }
    } else {
      console.warn('Unknown action received:', event.data && event.data.action);
    }
  }
}
