<div class="login-container d-flex flex-column justify-content-center align-items-center shadow">
  <div class="login-card p-5">
    <!-- Logo -->
    <div class="login-logo mx-auto">
    </div>

    <!-- OAuth Login -->
    <form (submit)="oidcLogin()" *ngIf="is_oauth" class="text-center">
      <div class="d-grid login-oidc">
        <button type="submit" class="btn btn-primary oidc-button" tabindex="1">
          <img src="assets/images/icon_microsoft.png" alt="Microsoft Icon" class="oidc-icon" />
          LOGIN IN WITH MICROSOFT
        </button>
      </div>
      <div class="divider mb-5">
        <span class="divider-text text-muted">or continue with email</span>
      </div>
    </form>

    <!-- Email Login -->
    <form (submit)="login()" *ngIf="is_oauth || !is_oauth">
      <div class="mb-5">
        <label for="username" class="login-form-label mb-1">Username:</label>
        <input id="username" name="username" type="text" class="form-control" 
              [(ngModel)]="username" required tabindex="2">
      </div>
      <div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <label for="password" class="login-form-label">Password:</label>
          <a [href]="forgotPasswordLink" target="_blank" class="login-form-label" tabindex="4">
            Forgot Password?
          </a>
        </div>
        <input id="password" name="password" type="password" class="form-control"
              [(ngModel)]="password" required tabindex="3">
      </div>
      <div class="d-grid">
        <div *ngIf="error_message" class="error-text text-center mb-3">
          <span><strong>Login Failed:</strong> {{ error_message }}</span>
        </div>
        <button type="submit" class="btn btn-primary login-button" tabindex="5">LOG IN</button>
      </div>
    </form>
  </div>
</div>
