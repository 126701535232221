import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { Observable, of } from "rxjs";
import { catchError } from 'rxjs/operators';
import { SearchContext } from './shared/search-context';
import { AuthService } from '../auth/auth.service';
import { ConnectorsAuthenticators } from '../auth';
import { Microsoft } from '../auth/microsoft.service';

const AuthenticatorsObject: any = ConnectorsAuthenticators;

@Injectable({
  providedIn: 'root'
})
export class SwirlService {

  private searchUrl: string;
  private paginationUrl: string;
  private authenticatorsUrl: string;
  private sourcesUrl: string;
  private brandingUrl: string;
  private promptsUrl: string;

  // New Authenticator: Add to DI
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
    private microsoftAuth: Microsoft
  ) {
    this.searchUrl = '';
    this.paginationUrl = '';
    this.authenticatorsUrl = '/api/swirl/sapi/authenticators/';
    this.sourcesUrl = '/api/swirl/sapi/searchproviders/';
    this.brandingUrl = '/api/swirl/sapi/branding/';
    this.promptsUrl = '/api/swirl/sapi/prompts/';
    configService.getConfig().subscribe(configResponse => {
      if (configResponse) {
        this.searchUrl = '/api/swirl/sapi/search/';
        this.paginationUrl = '/api/swirl/sapi/results/';
      }
    });
  }

  getSources(): Observable<any> {
    const csrfToken = this.authService.getCookie('csrftoken'); // get the CSRF token from the cookie
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken) // include the CSRF token in the request headers
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');
    // TODO: can we pass the params to GET?
    const response = this.http.get<SwirlResponse>(
      this.sourcesUrl,
      { headers }
    );
    return response;
  }

  getPrompts(): Observable<any> {
    const csrfToken = this.authService.getCookie('csrftoken');
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken)
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');

    return this.http.get<SwirlResponse>(this.promptsUrl, { headers }).pipe(
      catchError(error => {
        return of([]); // Return an empty array if the endpoint fails
      })
    );
  }

  getAuthenticators(): Observable<any> {
    const csrfToken = this.authService.getCookie('csrftoken'); // get the CSRF token from the cookie
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken) // include the CSRF token in the request headers
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');
    // TODO: can we pass the params to GET?
    const response = this.http.get<SwirlResponse>(
      this.authenticatorsUrl,
      { headers }
    );
    return response;
  }

  removeToken(idp: string): Observable<any> {
    const csrfToken = this.authService.getCookie('csrftoken'); // get the CSRF token from the cookie
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken) // include the CSRF token in the request headers
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');
    // TODO: can we pass the params to GET?
    const response = this.http.get<SwirlResponse>(
      `/api/swirl/sapi/authenticators/${idp.toLowerCase()}/remove_token`,
      { headers }
    );
    return response;
  }

  getBrandingValues(target: string): Observable<any> {
    const csrfToken = this.authService.getCookie('csrftoken'); // get the CSRF token from the cookie
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken) // include the CSRF token in the request headers
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');
    
    const response = this.http.get<any>(
      `${this.brandingUrl}?target=${target}`,
      { headers }
    );
    
    return response;
  }

  async search(searchContext: SearchContext): Promise<Observable<SwirlResponse>> {
    // Build URL query parameters manually using string concatenation.
    const query = encodeURIComponent(searchContext.query);
    const rows = searchContext.rows.toString();
    const start = searchContext.start.toString();
    const page = searchContext.page;
    const searchId = searchContext.searchId;
    const refines = searchContext.refine;
    let providers = searchContext.providers;
    const filters = searchContext.filters;
    const connectorsNames = searchContext.connectorsNames;
    if (refines && refines.length > 0) {
      providers = refines.map(r => r.value);
    }

    let currentUrl: string;
    if (searchId == '') {
      currentUrl = this.searchUrl;
    } else {
      currentUrl = this.paginationUrl;
    }

    currentUrl += "?qs=" + query + "&format=json&search_id=" + searchId + "&page=" + page;
    if (providers.length > 0) {
      currentUrl += '&providers=' + providers.join(",");
    }
    if (filters.size > 0) {
      filters.forEach((value, key) => {
        const param = value.split(':');
        currentUrl += '&' + param[0] + '=' + param[1];
      });
    }

    const csrfToken = this.authService.getCookie('csrftoken'); // get the CSRF token from the cookie
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken)
      .set('Authorization', 'Token ' + this.authService.getToken())
      .set('Content-Type', 'application/json');
    
    // Add additional authentication headers if required by connectors.
    for (const connector of connectorsNames) {
      const isNeededAuthenticator = AuthenticatorsObject[connector];
      if (isNeededAuthenticator) {
        let authenticator;
        switch (AuthenticatorsObject[connector]) {
          case "Microsoft":
            authenticator = this.microsoftAuth;
            break;
          default:
            console.warn(`No Authenticator for ${connector}`);
        }
        if (authenticator) {
          let token = authenticator?.getToken();
          let isAuthenticated = authenticator?.isAuthenticated();
          if (!isAuthenticated) {
            token = await authenticator?.updateToken();
            isAuthenticated = authenticator?.isAuthenticated();
          }
          if (token && isAuthenticated) {
            headers = headers.set(`Authorization${authenticator?.name?.toLowerCase()}`, token);
          }
        }
      }
    }
    // TODO: can we pass the params to GET?
    const response = this.http.get<SwirlResponse>(
      currentUrl,
      { headers }
    );
    return Promise.resolve(response);
  }
}

export class SwirlResponse {
  messages?: Array<string>;
  info?: any; // Map<string, SwirlResultInfo>
  results?: Array<SwirlResult>;
}

export class SwirlResultInfo {
  [index: string]: any;
  retrieved?: number;
  filter_url?: string;
  search_time?: number;
  found_total?: number;
  retrieved_total?: number;
}

export class SwirlResult {
  [index: string]: any;
  swirl_rank?: number;
  swirl_score?: number;
  searchprovider?: string;
  searchprovider_rank?: number;
}
