import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js/auto';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'galaxy-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: false
})
export class GalaxyChart implements OnInit, AfterViewInit, OnChanges {

  @Input() data: any[] = [];  // New input property for the dataset
  @ViewChild('canvas') canvas?: ElementRef<HTMLCanvasElement>;

  chart?: Chart;
  canShow = false;
  isLoading = false; // leave it or remove if not used here
  mode: string = localStorage.getItem('isDark') === 'Dark' ? 'Dark' : 'Light';

  legendConfig = {
    labels: {
      color: this.mode === 'Dark' ? 'rgba(255, 255, 255, 0.5)' : '#666'
    }
  };

  // (Keep your backgroundColors and hoverBackgroundColors as-is)
  backgroundColors = [
    'rgba(255, 99, 132, 0.7)',
    'rgba(22, 160, 133, 0.7)',
    'rgba(241, 196, 15, 0.7)',
    'rgba(211, 84, 0, 0.7)',
    'rgba(41, 128, 185, 0.7)',
    'rgba(142, 68, 173, 0.7)',
    'rgba(44, 62, 80, 0.7)',
    'rgba(39, 174, 96, 0.7)',
    'rgba(192, 57, 43, 0.7)',
    'rgba(127, 140, 141, 0.7)'
  ];

  hoverBackgroundColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(22, 160, 133, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(44, 62, 80, 1)',
    'rgba(39, 174, 96, 1)',
    'rgba(192, 57, 43, 1)',
    'rgba(127, 140, 141, 1)'
  ];

  constructor(
    private localStorageService: LocalStorageService
  ) {
    // Keep the theme subscription if needed
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light';
      this.updateViewForMode();
    });
  }

  ngOnInit(): void {
    // Optionally, if you need to do something when component initializes
  }

  ngAfterViewInit(): void {
    // Render the chart once the view is initialized and if data is available.
    if (this.data && this.data.length) {
      this.renderChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      // When the input data changes, re-render the chart.
      this.renderChart();
    }
  }

  renderChart(): void {
    if (!this.data.length) {
      this.destroyChart();
      this.canShow = false;
      return;
    }
    const drawFn = this.checkSupported(this.data);
    if (!drawFn) {
      this.destroyChart();
      this.canShow = false;
      return;
    } else {
      this.canShow = true;
      drawFn(this.data);
      this.updateViewForMode();
    }
  }

  /** Updates the chart appearance based on the current theme. */
  updateViewForMode() {
    if (this.chart?.config.options?.scales) {
      const xAxis: any = this.chart.config.options.scales.x;
      const yAxis: any = this.chart.config.options.scales.y;
      // Update dataset colors
      this.chart.data.datasets.forEach(dataset => {
        dataset.backgroundColor = this.backgroundColors;
        dataset.hoverBackgroundColor = this.hoverBackgroundColors;
      });
      // Update axis styling based on theme
      if (this.mode === "Dark") {
        xAxis.grid.color = 'rgba(255, 255, 255, 0.5)';
        yAxis.grid.color = 'rgba(255, 255, 255, 0.5)';
        xAxis.ticks.color = 'rgba(255, 255, 255, 0.5)';
        yAxis.ticks.color = 'rgba(255, 255, 255, 0.5)';
        xAxis.title.color = 'rgba(255, 255, 255, 0.5)';
        yAxis.title.color = 'rgba(255, 255, 255, 0.5)';
      } else {
        xAxis.grid.color = 'rgba(0, 0, 0, 0.1)';
        yAxis.grid.color = 'rgba(0, 0, 0, 0.1)';
        xAxis.ticks.color = '#666';
        yAxis.ticks.color = '#666';
        xAxis.title.color = '#666';
        yAxis.title.color = '#666';
      }
    }
    this.chart?.update();
  }

  /** Destroys the current chart instance if it exists. */
  private destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  /** Helper method to instantiate a new chart using the given configuration. */
  private createChart(config: ChartConfiguration) {
    this.destroyChart();
    if (this.canvas?.nativeElement) {
      this.chart = new Chart(this.canvas.nativeElement, config);
    } else {
      console.error("Canvas element is not available.");
    }
  }

  /** Helper method to return the numeric field names from the first data row, excluding common identifier fields. */
  private getNumericFields(data: any[]): string[] {
    if (!data.length) {
      return [];
    }
    return Object.keys(data[0]).filter(key =>
      typeof data[0][key] === 'number' &&
      key.toLowerCase() !== 'id' &&
      key.toLowerCase() !== 'permalink'
    );
  }

  /** Helper method to return the string field names from the first data row, excluding common identifier fields. */
  private getStringFields(data: any[]): string[] {
    if (!data.length) {
      return [];
    }
    return Object.keys(data[0]).filter(key =>
      typeof data[0][key] === 'string' &&
      key.toLowerCase() !== 'permalink'
    );
  }

  /** Helper function for standardizing chart styles */
  private defaultScale(titleText: string): any {
    return {
      title: {
        display: true,
        text: titleText,
        font: {
          weight: 600,
          size: 14
        }
      },
      ticks: {
        font: {
          weight: 400,
          size: 12
        },
        maxTicksLimit: 6
      }
    };
  }

  /**
   * Converts a 2D dataset (array of rows) into an array of objects.
   * Assumes that even-index rows contain field names and odd-index rows contain values.
   */
  extractData(dataset: any[][]): any[] {
    const extracted: any[] = [];
    for (let i = 0; i < dataset.length; i += 2) {
      const row: any = {};
      for (let j = 0; j < dataset[i].length; j++) {
        const key = dataset[i][j];
        let value = dataset[i + 1][j];
        // Convert numeric-like values to numbers
        if (!isNaN(value) && value !== "" && value !== null) {
          value = Number(value);
        }
        row[key] = value;
      }
      extracted.push(row);
    }
    return extracted;
  }

  /**
   * Determines the appropriate chart type based on the data fields.
   * Returns a bound function that draws the selected chart or null if none is suitable.
   */
  checkSupported(data: any[]): CallableFunction | null {
    if (!data.length) {
      return null;
    }
    const keys = Object.keys(data[0]);
    const numericFields = this.getNumericFields(data);
  
    if (numericFields.length === 0) {
      // When no numeric field exists, add a pseudo count field.
      data.forEach(row => row['[count]'] = 1);
      return (keys.length === 1) ? this.drawBar.bind(this) : this.drawStacked.bind(this);
    } else if (numericFields.length === 1) {
      return this.drawBar.bind(this);
    } else if (numericFields.length === 2) {
      const [n1, n2] = numericFields;
      const range1 = Math.max(...data.map(d => d[n1])) - Math.min(...data.map(d => d[n1]));
      const range2 = Math.max(...data.map(d => d[n2])) - Math.min(...data.map(d => d[n2]));
      if (range1 > 0 && range2 > 0) {
        return this.drawScatter.bind(this);
      } else {
        return this.drawBar.bind(this);
      }
    } else if (numericFields.length >= 3) {
      const [n1, n2, n3] = numericFields;
      const sizeRange = Math.max(...data.map(d => d[n3])) - Math.min(...data.map(d => d[n3]));
      if (sizeRange > 0) {
        return this.drawBubble.bind(this);
      } else {
        return this.drawBar.bind(this);
      }
    }
    return null;
  }

  // ------------------ Chart Drawing Methods ------------------

  drawBar(data: any[]) {
    const keys = Object.keys(data[0]);
    let xField: string | undefined;
    let yField: string | undefined;
  
    // Use getStringFields() to get candidate fields for the x-axis.
    const stringFields = this.getStringFields(data);
    // Use getNumericFields() to get candidate fields for the y-axis.
    const numericFields = this.getNumericFields(data);
  
    // Prefer the first available string for xField, and first numeric for yField.
    xField = stringFields[0] || keys.find(k => k !== (numericFields[0] || ''));
    yField = numericFields[0];
  
    if (!xField) {
      console.error("No valid string field for X-axis.");
      return;
    }
    if (!yField) {
      console.error("No valid numeric field for Y-axis.");
      return;
    }
  
    const config: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: data.map(row => row[xField]),
        datasets: [{
          label: yField,
          data: data.map(row => row[yField])
        }]
      },
      options: {
        plugins: { legend: this.legendConfig },
        responsive: true,
        scales: {
          x: this.defaultScale(xField),
          y: this.defaultScale(yField)
        }
      }
    };
    this.createChart(config);
  }

  drawStacked(data: any[]) {    
    // Get candidate fields using our helpers
    const numericFields = this.getNumericFields(data);
    if (numericFields.length === 0) {
      console.error("No numeric field found for stacked chart.");
      return;
    }
    const numericField = numericFields[0];
  
    const stringFields = this.getStringFields(data);
    if (stringFields.length < 2) {
      console.error("Insufficient string fields for stacked chart.");
      return;
    }
    const [categoryField, datasetField] = stringFields;
    
    // Get unique categories and dataset groups
    const categories = Array.from(new Set(data.map(row => row[categoryField])));
    const datasetGroups = Array.from(new Set(data.map(row => row[datasetField])));
    
    // Build a mapping for each dataset group across categories
    const mapping: Record<string, Record<string, number>> = {};
    datasetGroups.forEach(group => {
      mapping[group] = {};
      categories.forEach(cat => mapping[group][cat] = 0);
    });
    data.forEach(row => {
      mapping[row[datasetField]][row[categoryField]] = row[numericField];
    });
    
    // Build datasets for Chart.js
    const datasets = datasetGroups.map(group => ({
      label: group,
      data: categories.map(cat => mapping[group][cat]),
      stack: group
    }));
    
    const config: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: categories,
        datasets: datasets
      },
      options: {
        plugins: { legend: this.legendConfig },
        responsive: true,
        scales: {
          x: this.defaultScale(categoryField),
          y: this.defaultScale(numericField) 
        }
      }
    };
    this.createChart(config);
  }

  drawBubble(data: any[]) {
    const numericFields = this.getNumericFields(data);
    if (numericFields.length < 3) {
      return this.drawBar(data);
    }
    const [xField, yField, rField] = numericFields;
    const maxR = Math.max(...data.map(d => d[rField])) || 1;
    const scaleFactor = 50 / maxR;  // Adjust to get reasonable bubble sizes
  
    const bubbleData = data.map(row => ({
      x: row[xField],
      y: row[yField],
      r: Math.max(row[rField] * scaleFactor, 5) // Ensure a minimum bubble size
    }));
  
    const config: ChartConfiguration = {
      type: 'bubble',
      data: {
        datasets: [{
          label: "Funding Data",
          data: bubbleData
        }]
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return `${xField}: ${context.raw.x}\n${yField}: ${context.raw.y}\n${rField}: ${context.raw.r}`;
              }
            }
          }
        },
        scales: {
          x: this.defaultScale(xField),
          y: this.defaultScale(yField)
        }
      }
    };
    this.createChart(config);
  }

  drawScatter(data: any[]) {
    // Get numeric fields (excluding "id")
    const numericFields = this.getNumericFields(data);
    // Get discrete (string) fields using our helper
    const discreteFields = this.getStringFields(data);
  
    // Use the first two numeric fields for x and y axes
    const xField = numericFields[0];
    const yField = numericFields[1];
  
    let configData: any;
    if (discreteFields.length === 0) {
      // Single dataset scatter if no discrete field is available
      configData = {
        datasets: [{
          label: "dataset",
          data: data.map(row => ({ x: row[numericFields[0]], y: row[numericFields[1]] }))
        }]
      };
    } else {
      // Multiple datasets separated by the first discrete field
      const groupField = discreteFields[0];
      const groups = Array.from(new Set(data.map(row => row[groupField])));
      configData = {
        datasets: groups.map(group => ({
          label: group,
          data: data
            .filter(row => row[groupField] === group)
            .map(row => ({ x: row[numericFields[0]], y: row[numericFields[1]] }))
        }))
      };
    }
    
    const config: ChartConfiguration = {
      type: 'scatter',
      data: configData,
      options: {
        responsive: true,
        plugins: { legend: this.legendConfig },
        scales: {
          x: this.defaultScale(xField),
          y: this.defaultScale(yField)
        }
      }
    };
    this.createChart(config);
  }
}
