<div class="login-container d-flex flex-column align-items-center">
  <div class="login-card p-5">
    <!-- Logo -->
    <div class="login-logo mx-auto">
    </div>

  <!-- Lockout Message -->
  <div class="error-text text-center mb-3">
    <strong>You have been locked out</strong> due to too many failed login attempts. Please contact a system administrator to reset your account.
  </div>
</div>
