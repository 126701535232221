<div class="row gx-0">
  <div class="col">
    <div *ngIf="isShowing">
      <app-summary></app-summary>
    </div>
  </div>
</div>

<div *ngIf="!isLoading">
  <!-- Info + Actions + Pagination Row -->
  <div class="row gx-0 align-items-center py-0">
    <!-- Info Column -->
    <div class="col-auto d-flex align-items-center">
      <app-info></app-info>
    </div>

    <!-- Actions Column -->
    <div class="col d-flex align-items-center">
      <app-actions></app-actions>
    </div>

    <!-- Pagination Column -->
    <div class="col-auto d-flex align-items-center">
      <app-pagination></app-pagination>
    </div>
  </div>

  <!-- Sort Search, Select Items Toggle, Show/Hide All Details Toggle -->
  <div class="row gx-0 align-items-center justify-content-between my-2">
    <!-- View By Dropdown -->
    <div 
      class="col-md-2 col-sm-4" 
      [class.d-none]="result.docs.length === 0">
      <app-sort-search></app-sort-search>
    </div>

    <!-- Select Items Toggle, Deselect All Link, and Show/Hide All Details Toggle -->
    <div *ngIf="isShowing && result.docs.length" class="col-md-auto col-sm-auto d-flex align-items-center ms-auto">
      <!-- Deselect All Button -->
      <button 
        *ngIf="checkedSelectItems" 
        class="btn btn-link select-deselect-link me-2" 
        (click)="toggleSelectDeselect()"
      >
        {{ isDeselected ? 'SELECT ALL' : 'DESELECT ALL' }}
      </button>

      <!-- Select Items Toggle -->
      <div class="form-check form-switch custom-toggle me-4">
        <input
          class="form-check-input"
          type="checkbox"
          id="selectItemsToggle"
          [checked]="checkedSelectItems"
          (change)="toggleChange()"
        />
        <label class="form-check-label ms-2" for="selectItemsToggle">
          {{ selectItemsLabel }}
        </label>
      </div>

      <!-- Show/Hide All Details Toggle -->
      <div class="form-check form-switch custom-toggle">
        <input
          class="form-check-input"
          type="checkbox"
          id="showDetailsToggle"
          [checked]="globalShowDetails"
          (change)="toggleAllDetails()"
        />
        <label class="form-check-label ms-2" for="showDetailsToggle">
          {{ globalShowDetails ? 'Hide ALL Details' : 'Show ALL Details' }}
        </label>
      </div>
    </div>
  </div>

  <!-- No Results Message -->
  <div *ngIf="!isLoading && result.docs.length === 0 && isShowing" class="row gx-0">
    <div class="col text-center my-3">
      <span class="fs-4">
        {{ isDateFilter ? "No results with dates available" : "No results available" }}
      </span>
    </div>
  </div>

  <!-- Results Table -->
  <div *ngIf="resultType === 'table' || (resultType === 'hybrid' && !isCardView)" class="row gx-0">
    <div class="col table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th *ngFor="let column of columnMapping" class="text-center text-nowrap">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of result.docs">
            <td *ngFor="let column of columnMapping" class="text-center text-nowrap">
              <ng-container *ngIf="column.field === 'title'">
                <a *ngIf="doc['url']" [href]="doc['url']" [innerHTML]="doc[column.field]"></a>
                <span *ngIf="!doc['url']" [innerHTML]="doc[column.field]"></span>
              </ng-container>

              <!-- Body Field -->
              <ng-container *ngIf="column.field === 'body'">
                <span [innerHTML]="doc[column.field]"></span>
              </ng-container>

              <!-- Other Fields -->
              <ng-container *ngIf="column.field !== 'title' && column.field !== 'body'">
                <span [innerHTML]="doc[column.field]"></span>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Results in Cards -->
  <ul 
    *ngIf="resultType === 'card' || (resultType === 'hybrid' && isCardView)" 
    class="list-group pt-3">
    <li 
      class="list-group-item px-2 py-3 mb-3" 
      *ngFor="let doc of result.docs">
      <app-result-card 
        [doc]="doc" 
        [highlight]="highlights[doc.id]" 
        [layout]="resultLayout" 
        [config]="config"
        [showDetailsEnabled]="true"
        [globalShowDetails]="globalShowDetails"
      >
      </app-result-card>
    </li>
  </ul>

  <!-- Bottom Actions and Pagination -->
  <div class="row align-items-center my-2">
    <!-- <div class="col">
      <app-actions></app-actions>
    </div> -->
    <div class="d-flex justify-content-end">
      <app-pagination></app-pagination>
    </div>
  </div>
</div>
