import { Component, OnInit } from '@angular/core';
import { SearchService } from '../search.service';

@Component({
    selector: 'charts-container',
    templateUrl: './charts-container.component.html',
    styleUrls: ['./charts-container.component.scss'],
    standalone: false
})
export class ChartsContainerComponent implements OnInit {
  chartsArray: any[][] = [];

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.searchService.currentResults.subscribe(result => {
      // Clear out previous charts when new results come in.
      this.chartsArray = [];
  
      for (let doc of result.docs) {
        if ('dataset' in doc['payload']) {
          const rawData = doc['payload']['dataset'];
          const data = this.extractData(rawData);
          if (data && data.length) {
            this.chartsArray.push(data);
          }
        }
      }
    });
  }

  /**
   * Converts a 2D dataset (array of rows) into an array of objects.
   * This helper is similar to the one in GalaxyChart.
   */
  extractData(dataset: any[][]): any[] {
    const extracted: any[] = [];
    for (let i = 0; i < dataset.length; i += 2) {
      const row: any = {};
      for (let j = 0; j < dataset[i].length; j++) {
        const key = dataset[i][j];
        let value = dataset[i + 1][j];
        // Convert numeric-like values to numbers
        if (!isNaN(value) && value !== "" && value !== null) {
          value = Number(value);
        }
        row[key] = value;
      }
      extracted.push(row);
    }
    return extracted;
  }
}
