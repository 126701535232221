import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OauthService } from '../../auth/oauth2-service';
import { AuthService } from 'src/app/auth/auth.service';
import { MsalAccessService } from 'src/app/auth/msal-service';
import { Microsoft } from 'src/app/auth/microsoft.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'oidc-callback',
    templateUrl: './oidc-callback.component.html',
    standalone: false
})
export class OidcCallbackComponent implements OnInit {
  fromChat: boolean = false;

  constructor(
    private router: Router,
    private oauthService: OauthService,
    private authService: AuthService,
    private msalInstance: MsalAccessService,
    private msauthService: Microsoft,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Use local storage instead of query parameters
    this.fromChat = localStorage.getItem('fromComponent') === 'chat';
    // Optionally clear it so it doesn't persist
    localStorage.removeItem('fromComponent');

    this.oauthService.events
      .pipe(filter((e: any) => e.type === 'token_received'))
      .subscribe(() => {
        const token = this.oauthService.getAccessToken();
        if (token) {
          this.authService.oidcLogin(token).subscribe(
            (data: any) => {
              if (data.token) {
                console.log('Token received and login successful');
                this.authService.setToken(data.token);
                this.authService.setUser(data.user);
                if (this.msalInstance.shouldUseTokenFromOauth) {
                  const oauthType = this.oauthService.getType();
                  if (oauthType === 'microsoft') {
                    this.msalInstance.setIsTokenFromOauth(true);
                    this.msauthService.setToken(token);
                  }
                }
                if (data.prompts_exists) {
                  console.log('Setting prompts status');
                  this.authService.setPromptsStatus(data.prompts_exists.toLowerCase() === 'true');
                }
                if (data.confidence_mixer) {
                  console.log('Setting Confidence Mixer status');
                  this.authService.setConfidenceMixerStatus(data.confidence_mixer.toLowerCase() === 'true');
                }
                if (data.chat_view) {
                  this.authService.setChatStatus(data.chat_view.toLowerCase() === 'true');
                }
                if (this.fromChat && this.authService.getChatStatus()) {
                  this.router.navigate(['/chat']);
                } else {
                  this.router.navigate(['/']);
                }
              } else if (data.error) {
                console.log(data.error);
              }
            },
            (error: any) => console.log(error)
          );
        }
      });
  }
}
