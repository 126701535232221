<div class="dropdown">
  <!-- Trigger button with custom icon -->
  <button 
    class="btn btn-outline-secondary dropdown-toggle p-0 m-0 border-0" 
    type="button" 
    id="profileDropdown" 
    data-bs-toggle="dropdown" 
    aria-expanded="false"
  >
    <img 
      src="assets/images/icon_user.png" 
      alt="User Profile Icon" 
      class="profile-icon"
    />
  </button>

  <!-- Dropdown menu -->
  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
    <li>
      <button class="dropdown-item" (click)="logout()">Log Out</button>
    </li>
    <li>
      <a class="dropdown-item" href="/galaxy">
        SWIRL AI Search
      </a>

    </li>
    <!-- <li>
      <button class="dropdown-item" (click)="toggleTheme()">Switch to {{ mode }} Mode</button>
    </li> -->
    <li>
      <a class="dropdown-item" [href]="swirlAdminLink" target="_blank">
        Manage SWIRL
      </a>
    </li>

    <!-- Authenticators Section -->
    <li *ngIf="authenticators.length > 0" class="dropdown-divider"></li>
    <li *ngFor="let a of authenticators">
      <div class="dropdown-item d-flex align-items-center">
        <label class="form-check-label me-2 authenticator-label">
          {{ a.name }}
        </label>
        <div class="form-check form-switch custom-toggle">
          <input 
            class="form-check-input"
            type="checkbox"
            [checked]="!a.expired"
            (change)="toggleChange($event, a.idp, a.name, a.expired)"
          />
        </div>
      </div>
    </li>
    <li class="dropdown-divider"></li>
    <li class="swirl-version dropdown-item">
      Version: {{ swirlVersion }}
    </li>
  </ul>
</div>
