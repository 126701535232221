/* Form Container */
.chat-box-form {
    width: 100%;
    display: flex;
    align-items: center; /* Vertically centers textarea and button */
}

/* Chat Textarea */
.chat-textarea {
    flex-grow: 1; /* Takes up remaining space */
    background-image: url("../../../assets/images/icon_search.png");
    background-repeat: no-repeat; /* Prevent tiling */
    background-position: 0.75rem 0.35rem; /* Position the icon */
    background-size: 1.5rem; /* Adjust icon size */
    padding-left: 3rem; /* Create space for the icon */
    background-color: var(--color-background-blue);
    height: 5.875rem; /* Match the button height */
    border: 3px solid var(--color-bright-blue);
    border-radius: 5px;
    box-sizing: border-box; /* Include padding in height/width calculations */
    line-height: 1.5; /* Ensure proper line spacing */
    resize: none; /* Prevent resizing for consistency */
    font-size: 1.125rem; /* Consistent with the placeholder font-size */
}

.chat-textarea::placeholder {
    font-style: italic;
    font-size: 1.125rem; /* Consistent with the textarea font-size */
}

  .chat-textarea:focus {
    background-color: var(--color-background-blue); /* Keep the same background color */
    border: 3px solid var(--color-bright-blue); /* Keep the same border */
    box-shadow: none; /* Disable Bootstrap's glow effect */
}

/* Chat Send Button */
.chat-send-btn {
    width: 10rem;
    height: 5.875rem; /* Match textarea height */
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.875rem;
    font-weight: 800;
    background-color: var(--color-bright-blue);
    color: var(--color-white);
    display: flex; /* Centers spinner and text */
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem; /* Space between textarea and button */
    border: 3px solid var(--color-bright-blue); /* Keep the same border as the input box */
    box-sizing: border-box; /* Ensure consistency with textarea */
}

/* Spinner Styling */
.spinner-border {
    position: absolute; /* Ensure the spinner stays centered */
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0; /* Remove margin adjustments */
}
