import { Injectable } from '@angular/core';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class MsalAccessService {
  private msalInstance: PublicClientApplication | null = null;
  public shouldUseTokenFromOauth: Boolean = false;
  public microsoftGalaxyOauth: Boolean = false;

  async loadConfig(
    msalConfig: Configuration, 
    shouldUseTokenFromOauth: Boolean, 
    microsoftGalaxyOauth: Boolean
  ): Promise<void> {
    this.msalInstance = new PublicClientApplication(msalConfig);

    // NEW in MSAL v2.35+:
    await this.msalInstance.initialize();

    // store booleans
    this.shouldUseTokenFromOauth = shouldUseTokenFromOauth;
    this.microsoftGalaxyOauth = microsoftGalaxyOauth;
  }

  getMsalInstance(): PublicClientApplication {
    if (!this.msalInstance) {
      throw new Error('MSAL is not initialized');
    }
    return this.msalInstance;
  }

  acquireTokenSilent(request: any): any {
    return this.msalInstance?.acquireTokenSilent(request)
  }

  setIsTokenFromOauth(isFrom: boolean): void {
    localStorage.setItem('isTokenPresentedFromOauth', isFrom.toString())
  }

  getIsTokenFromOauth(): boolean {
    return localStorage.getItem('isTokenPresentedFromOauth') == 'true'
  }

}
