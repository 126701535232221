.ai-summary-heading {
    margin-bottom: 0.5rem; /* Equivalent to Bootstrap's mb-2 */
    color: var(--color-bright-blue);
    font-weight: 700;
    font-size: 1.5rem;
}

.ai-error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-error); /* Use your existing error color variable */
    padding: 3.5rem; /* Large padding for space around */
    margin: 1.5rem 0; /* Space above and below */
}

.ai-summary-metadata {
    font-size: 0.75rem;
    font-weight: 700;
    text-align: right;
}

.ai-summary-spinner {
    color: var(--color-bright-blue);
  }
