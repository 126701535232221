import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ResultsComponent } from './results/results.component';
import { SortSearchComponent } from './sort-search/sort-search.component';
import { BoxComponent } from './box/box.component';
import { SummaryComponent } from './summary/summary.component';
import { ProfileBoxComponent } from './profile-box/profile-box.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FacetComponent } from './facet/facet.component';
import { InfoComponent } from './info/info.component';
import { FacetNavComponent } from './facet-nav/facet-nav.component';
import { ResultCardComponent } from './result-card/result-card.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionsComponent } from './actions/actions.component';
import { SpyglassComponent } from './spyglass/spyglass.component';
import { JsonFieldComponent } from './json-field/json-field.component';
import { SwirlMixerFilterComponent } from './swirl-mixer-filter/swirl-mixer-filter.component';
import { TitleFieldComponent } from './title-field/title-field.component';
import { AuthorshipFieldComponent } from './authorship-field/authorship-field.component';
import { BodyFieldComponent } from './body-field/body-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from './config.service';
import { MsalAccessService } from '../auth/msal-service';
import { OauthService } from '../auth/oauth2-service';
import { WebSocketService } from './websockets.service';
import { firstValueFrom, timer } from 'rxjs';
import { switchMap, filter, first, tap } from 'rxjs/operators';
import { ConfigResponse } from '../spyglass-search/shared/config-response';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { GalaxyChart } from './chart/chart.component';
import { ChartsContainerComponent } from './charts-container/charts-container.component';
import { CookieConsentService } from '../shared/cookie-consent.service';

export function initializeApp(
  configService: ConfigService,
  msalService: MsalAccessService,
  oauthService: OauthService,
  webSocketService: WebSocketService
) {
  return async () => {
    const pollingInterval = 1000; // Interval between polling attempts (in milliseconds)

    try {
      const configResponse = await firstValueFrom(
        timer(0, pollingInterval).pipe(
          tap(() => console.log('Polling for config...')),
          switchMap(() => configService.getConfig('default')),
          tap((configResponse) => console.log('Config response:', configResponse)),
          filter(
            (configResponse): configResponse is ConfigResponse =>
              configResponse !== null && configResponse.msalConfig !== null
          ),
          first() // Completes after the first matching value
        )
      );

      if (!configResponse) {
        console.error('Config response is undefined.');
        return; // Early return if configResponse is undefined
      }

      const oauthConf = configResponse.oauthConfig;
      const msalConf = configResponse.msalConfig;
      const shouldUseTokenFromOauth = configResponse.shouldUseTokenFromOauth ?? false;
      const webSocketConf = configResponse.webSocketConfig;
      const microsoftGalaxyOauth = configResponse.microsoftGalaxyOauth;

      if (oauthConf) oauthService.loadConfig(oauthConf);
      if (webSocketConf) webSocketService.loadConfig(webSocketConf);
      msalService.loadConfig(msalConf, shouldUseTokenFromOauth, microsoftGalaxyOauth);
    } catch (error) {
      console.error('Error during app initialization:', error);
    }
  };
}

@NgModule({
  declarations: [
    ResultsComponent,
    BoxComponent,
    ProfileBoxComponent,
    SummaryComponent,
    PaginationComponent,
    FacetComponent,
    InfoComponent,
    FacetNavComponent,
    ResultCardComponent,
    ActionsComponent,
    SortSearchComponent,
    SpyglassComponent,
    JsonFieldComponent,
    SwirlMixerFilterComponent,
    TitleFieldComponent,
    AuthorshipFieldComponent,
    BodyFieldComponent,
    GalaxyChart,
    ChartsContainerComponent
  ],
  exports: [
    ResultsComponent,
    BoxComponent,
    ProfileBoxComponent,
    SummaryComponent,
    PaginationComponent,
    FacetComponent,
    InfoComponent,
    FacetNavComponent,
    ResultCardComponent,
    ActionsComponent,
    SortSearchComponent,
    SpyglassComponent,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    GalaxyChart,
    ChartsContainerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
  ],
  providers: [
    ConfigService,
    MsalAccessService,
    OauthService,
    WebSocketService,
    CookieConsentService,
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(
        inject(ConfigService),
        inject(MsalAccessService),
        inject(OauthService),
        inject(WebSocketService)
      );
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class SpyglassSearchModule {}
