import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FieldView } from '../shared/config-response';

@Component({
    selector: 'app-authorship-field',
    templateUrl: './authorship-field.component.html',
    styleUrls: ['./authorship-field.component.scss'],
    standalone: false
})
export class AuthorshipFieldComponent implements OnInit, OnChanges {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  provider: string = "";
  url: string = "";

  constructor() { }

  ngOnInit(): void {
    this.extractFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] || changes['fields']) {
      this.extractFields();
    }
  }

  extractFields(): void {
    this.provider = "";
    this.url = "";

    if (this.item && this.fields[0] && this.item[this.fields[0]]) {
      this.provider = this.item[this.fields[0]];
    }
    if (this.item && this.fields[1] && this.item[this.fields[1]]) {
      this.url = this.item[this.fields[1]];
    }
  }

}
