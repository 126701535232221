<div class="list-group">
  <!-- Loop through buckets -->
  <label 
    class="list-group-item d-flex align-items-center justify-content-between"
    *ngFor="let bucket of facet['buckets']; let i = index;"
  >
    <div class="d-flex align-items-center flex-grow-1 overflow-hidden">
      <input 
        class="form-check-input me-2" 
        type="checkbox" 
        [checked]="bucket.checked" 
        (change)="check(bucket)"
        [attr.aria-label]="bucket.label"
      />
      <span 
        class="text-truncate truncate-label" 
        title="{{ bucket.label }}"
      >
        {{ bucket.label }}
      </span>
    </div>
    <span class="badge ms-2">{{ bucket.count }}</span>
  </label>

  <!-- Clear All Button -->
  <div class="list-group-item d-flex justify-content-end pt-2 clear-all-item">
    <a 
      href="javascript:void(0);" 
      class="clear-all-link" 
      (click)="clear()"
    >
      CLEAR ALL
    </a>
  </div>
</div>
