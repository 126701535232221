import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigResponse, FieldView, ResultCard } from "../shared/config-response";
import { CartItem, ShoppingCart } from '../shared/shopping-cart';
import { ShoppingCartService } from '../shopping-cart.service';
import { SearchService } from '../search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss'],
  standalone: false,
})
export class ResultCardComponent implements OnInit, OnDestroy {

  @Input() doc: any;
  @Input() highlight: any;
  @Input() layout: ResultCard = new ResultCard();
  @Input() config: ConfigResponse = new ConfigResponse();
  @Input() showDetailsEnabled: boolean = true;
  @Input() globalShowDetails: boolean = false;

  toggleText: string;
  showDetails: boolean;
  cartText: string;
  cartImage?: string;
  inCart: boolean;
  isPayload: boolean;
  isChecked: boolean;
  isCheckboxRendered: boolean;

  isSelectedItemsSubscription: Subscription | undefined;
  isCleanAllToggleSubscription: Subscription | undefined;
  selectedMixerSubscription: Subscription | undefined;

  minimumSwirlScore: number;
  minimumConfidenceScore: number;
  isConfidenceMixer: boolean; // Track whether the Confidence Mixer is selected

  constructor(public shoppingCartService: ShoppingCartService, private searchService: SearchService) {
    this.toggleText = 'SHOW DETAILS';
    this.showDetails = false;
    this.cartText = '';
    this.inCart = false;
    this.isPayload = false;
    this.isChecked = false;
    this.isCheckboxRendered = true;
    this.minimumSwirlScore = 100; // Default value
    this.minimumConfidenceScore = 0.7; // Default value
    this.isConfidenceMixer = false; // Default to false
  }

  ngOnInit(): void {  
    // Initialize thresholds from config
    if (this.config.minimumSwirlScore) {
      this.minimumSwirlScore = this.config.minimumSwirlScore;
    }
    if (this.config.minimumConfidenceScore) {
      this.minimumConfidenceScore = this.config.minimumConfidenceScore;
    }

    // Subscribe to the selected Mixer, from SearchService.resultMixer
    this.selectedMixerSubscription = this.searchService.resultMixer.subscribe((selectedMixer: string) => {
      // Check if the Confidence Mixer is selected
      this.isConfidenceMixer = (selectedMixer === 'RelevancyConfidenceMixer');
    });

    // Other subscriptions
    this.isSelectedItemsSubscription = this.searchService.isSelectedItems.subscribe((isSelected) => {
      this.isCheckboxRendered = isSelected;
      if (!isSelected) {
        this.searchService.clearSelectedItems();
      } else {
        this.isChecked = false;
        if (this.searchService.cleanAllToggle.value) {
          this.isChecked = this.searchService.isItemSelected(this.doc['swirl_id']);
        } else {
          if (this.isCheckboxRendered) {
            this.isChecked = this.searchService.addToSelectedItems(this.doc['swirl_id'], false, this.doc['swirl_score']);
          }
        }
      }
    });

    this.isCleanAllToggleSubscription = this.searchService.isCleanAllToggle.subscribe((isCleanAllToggle) => {
      if (isCleanAllToggle) {
        this.isChecked = this.searchService.isItemSelected(this.doc['swirl_id']);
      }
    });

    this.inCart = false;
    this.isPayload = Object.keys(this.doc['payload']).length > 0;
    this.cartText = this.config.cart.addText;
    this.cartImage = this.config.cart.addImage;

    this.shoppingCartService.currentShoppingCart.subscribe(cart => {
      if (this.inCart && !cart.items.has(this.doc['id'])) {
        this.inCart = false;
        this.cartText = this.config.cart.addText;
        this.cartImage = this.config.cart.addImage;
      } else if (!this.inCart && cart.items.has(this.doc['id'])) {
        this.inCart = true;
        this.cartText = this.config.cart.removeText;
        this.cartImage = this.config.cart.removeImage;
      }
    });

    // Ensure that layout.details is populated. If not, default to layout.summary.
    if (!this.layout.details || this.layout.details.length === 0) {
      this.layout.details = this.layout.summary;
    }

    // Extract and assign authorship data
    this.assignAuthorshipData();
  }

  // Method to extract authorship data from layout.summary
  assignAuthorshipData(): void {
    // Check if layout.summary exists
    if (this.layout.summary) {  
      // Traverse layout.summary for authorship info
      for (const row of this.layout.summary) {
        for (const lists of row) {
          for (const list of lists) {
            for (const item of list) {
              if (item.type === 'authorship') {  
                // Assign provider from searchprovider and URL from doc
                const provider = this.doc.searchprovider || 'Unknown SearchProvider';
                const url = this.doc.url || '';
  
                // Assign to doc.authorship
                this.doc.authorship = { provider, url };
                return; // Exit after assigning authorship
              }
            }
          }
        }
      }
    }
  }

  // Helper method to extract field values from the document
  private getFieldValue(field: string, item: any): string {
    const fieldIndex = item.fields?.findIndex((f: string) => f === field);
    return fieldIndex !== -1 && this.doc[field] ? this.doc[field] : '';
  }
  
  // Logic for whether to show the star icon
  shouldShowStar(): boolean {
    if (this.isConfidenceMixer) {
      // Confidence Mixer: Check against minimumConfidenceScore
      return this.doc['swirl_confidence_score'] > this.minimumConfidenceScore;
    } else {
      // Default Mixer: Check against minimumSwirlScore
      return this.doc['swirl_score'] > this.minimumSwirlScore;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.globalShowDetails) {
      // Reset the override flag when the global state changes
      this.isOverridden = false;

      // Synchronize local state with global state
      this.showDetails = this.globalShowDetails;

      // Update toggleText dynamically
      this.toggleText = this.showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS';
    }
  }

  private isOverridden: boolean = false; // Track if individual toggle overrides global state

  toggleDetails(): void {
    if (this.showDetailsEnabled) {
      // Override the global state for this card
      this.isOverridden = true;
  
      // Toggle the card's details
      this.showDetails = !this.showDetails;
      this.toggleText = this.showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS';
    }
  }

  toggleCart(): void {
    if (this.inCart) {
      this.shoppingCartService.remove(this.doc['id']);
    } else {
      const cartItem = new CartItem();
      cartItem.id = this.doc['id'];
      this.shoppingCartService.add(cartItem);
    }
  }

  check() {
    this.isChecked
      ? this.searchService.addToSelectedItems(this.doc['swirl_id'], true, 0)
      : this.searchService.removeFromSelectedItems(this.doc['swirl_id']);
  }

  ngOnDestroy() {
    if (this.isSelectedItemsSubscription) this.isSelectedItemsSubscription.unsubscribe();
    if (this.isCleanAllToggleSubscription) this.isCleanAllToggleSubscription.unsubscribe();
    if (this.selectedMixerSubscription) this.selectedMixerSubscription.unsubscribe();
  }
}
