import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { SpyglassComponent } from './spyglass-search/spyglass/spyglass.component';
import { SpyglassChatComponent } from './spyglass-chat/spyglass-chat/spyglass-chat.component';
import { LoginComponent } from './login/login.component';
import { LockoutComponent } from "./lockout/lockout.component";
import { MicrosoftCallbackComponent } from './spyglass-search/microsoft-callback/microsoft-callback.component';
import { OidcCallbackComponent } from './spyglass-search/oidc-callback/oidc-callback.component';
import { AuthGuard } from './auth/auth.guard';
import { ChatAuthGuard } from './auth/chat-auth.guard';
import { NonAuthGuard } from './auth/nonauth.guard';
import { PreChatComponent } from './pre-chat/pre-chat.component';

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },
  { path: 'search', component: SpyglassComponent, canActivate: [AuthGuard] },
  { path: 'chat', component: SpyglassChatComponent, canActivate: [ChatAuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [NonAuthGuard] },
  { path: 'lockout', component: LockoutComponent, canActivate: [NonAuthGuard] },
  { path: 'microsoft-callback', component: MicrosoftCallbackComponent, canActivate: [AuthGuard] },
  { path: 'oidc-callback', component: OidcCallbackComponent, canActivate: [NonAuthGuard] },
  { path: 'pre-chat', component: PreChatComponent },
  { path: 'search/:subpath', redirectTo: 'search', pathMatch: 'full' },
  { path: 'chat/:subpath', redirectTo: 'chat', pathMatch: 'full' },
  { path: '**', redirectTo: 'search' }
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}