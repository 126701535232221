import { Component, OnInit } from '@angular/core';
import {ConfigService} from "../config.service";
import { ResultsComponent } from '../results/results.component';
import {SearchService} from "../search.service";
import {FacetGroup} from "../shared/config-response";

@Component({
    selector: 'app-facet-nav',
    templateUrl: './facet-nav.component.html',
    styleUrls: ['./facet-nav.component.scss'],
    standalone: false
})
export class FacetNavComponent implements OnInit {
  facetLayout: Array<FacetGroup>;
  isActive: boolean;
  isLoading: boolean;
  active: number;

  constructor(private configService: ConfigService, private searchService: SearchService) {
    this.facetLayout = [];
    this.isActive = false;
    this.isLoading = false;
    this.active = 0;
  }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(config => {
      if(config) {
        this.facetLayout = config.facetLayout;
      }
    });
    this.searchService.currentFacets.subscribe(facets => {
      this.isActive = !!facets.count;
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased) {
        this.facetLayout = [];
        this.isActive = false;
      }
    })
  }

}
