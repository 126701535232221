import { Component, OnInit } from '@angular/core';
import { SearchContext } from '../shared/search-context';
import { Result } from '../shared/result';
import { SearchService } from '../search.service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: false,
})
export class PaginationComponent implements OnInit {
  result: Result = new Result(); // Initialize directly
  searchContext: SearchContext = new SearchContext(); // Initialize directly
  page: number = 1; // Inline initialization
  pageSizes: Array<number> = []; // Inline initialization
  isLoading: boolean = false; // Inline initialization

  constructor(
    private searchService: SearchService,
    private configService: ConfigService
  ) {
    // Constructor remains clean for DI
  }

  ngOnInit() {
    this.searchService.currentResults.subscribe(result => (this.result = result));
    this.searchService.currentSearchContext.subscribe(searchContext => {
      this.searchContext = searchContext;
      this.page = Math.floor(searchContext.start / searchContext.rows) + 1;
    });
    this.configService.getConfig().subscribe(config => {
      if (config) {
        this.pageSizes = config.pageSizes || [10, 25, 50];
      }
    });
    this.searchService.isLoading.subscribe(isLoading => (this.isLoading = isLoading));
  }

  changePageSize(pageSize: number) {
    this.page = 1;
    this.searchService.changeRows(pageSize);
  }

  changePage(page: number) {
    this.searchService.changePage(page, (page - 1) * this.searchContext.rows);
  }

  // New methods for generating page numbers
  getLastPage(): number {
    // Calculate the number of pages based on retrieved results, not total results
    const totalRetrieved = this.result.numRetrieved || 0;
    return Math.ceil(totalRetrieved / this.searchContext.rows);
  }

  getPages(): number[] {
    const totalPages = this.getLastPage();
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
}
