import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../config.service';
import { SearchService } from '../search.service';
import { SwirlService } from '../swirl.service';
import { LocalStorageService } from '../local-storage.service';
import { CookieConsentService } from 'src/app/shared/cookie-consent.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-spyglass',
    templateUrl: './spyglass.component.html',
    styleUrls: ['./spyglass.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SpyglassComponent implements OnInit {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    { value: 'default', name: 'All Sources', connector: 'default' }
  ];
  prompts: { value: string; name: string }[] = [];
  isEnabledCharts: boolean = false;
  promptsExists: boolean = true;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private searchService: SearchService,
    private swirl: SwirlService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private titleService: Title,
    private cookieConsentService: CookieConsentService
  ) {
    this.isEnabledCharts = false;
    this.titleService.setTitle('SWIRL AI Search');
    this.promptsExists = this.auth.getPromptsStatus();
  }

  ngOnInit(): void {
    // Initialize cookie consent banner
    this.cookieConsentService.initCookieConsent();

    // Handle browser back button logic
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.handleBackButton();
        }
      }
    });

    // Dark mode toggle
    if (!document.body.classList.contains("dark-theme") && this.mode === 'Dark') {
      document.body.classList.toggle("dark-theme");
    }

    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light';
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
    });

    // Fetch sources and prompts
    this.swirl.getSources().subscribe(async response => {
      response.filter((source: any) => source.active).forEach((source: any) => {
        this.sources.push({
          value: source.id,
          name: source.name,
          connector: source.connector
        });
      });

      const params = this.route.snapshot.queryParamMap;
      const scope = params.get('scope') || 'default';

      this.configService.getConfig(scope).subscribe(config => {
        if (config && !this.isLoading) {
          this.hasFacets = config.facetLayout != null;
          this.type = config['type'];
        }
      });

      if (this.promptsExists) {
        this.swirl.getPrompts().subscribe({
          next: (response: any) => {
            response.forEach((prompt: any) => {
              this.prompts.push({
                value: prompt.id,
                name: this.formatPromptName(prompt.name),
              });
            });
          },
          error: (error: any) => {
            console.error('Failed to load prompts:', error.message); // Keep this for meaningful errors
          },
        });
      }

      this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    });
    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts);
  }

  formatPromptName(name: string): string {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  ngOnDestroy(): void {
    this.cookieConsentService.destroyCookieConsent();
  }  

  handleBackButton(): void {
    this.searchService.changeEraseEvent(true);
  }

  handleLogoClick(): void {
    this.searchService.changeEraseEvent(true);
  }
}
