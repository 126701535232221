<ng-container *ngIf="dataReady">
  <!-- Search Form -->
  <form 
    class="row align-items-start mb-0 w-100 gx-3" 
    [formGroup]="searchForm"  
    (ngSubmit)="onSubmit()"
  >
    <!-- All Sources Dropdown -->
    <div class="col-md-2 col-lg-2 d-flex align-items-start">
      <div class="dropdown w-100">
        <button 
          id="sourcesDropdown"
          class="btn btn-outline-secondary dropdown-toggle w-100 text-start" 
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
          title="{{ selectedSourcesNames }}"
        >
          <span class="dropdown-button-text text-truncate">
            {{ selectedSourcesNames }}
          </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="sourcesDropdown">
          <li *ngFor="let source of sources" class="dropdown-item">
            <div class="form-check">
              <input 
                class="form-check-input" 
                type="checkbox" 
                [checked]="source.selected" 
                (change)="onSourceToggle(source)"
                id="source-{{ source.value }}"
              />
              <label 
                class="form-check-label text-truncate" 
                for="source-{{ source.value }}" 
                title="{{ source.name }}"
              >
                {{ source.name }}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Query Input and Search Button -->
    <div class="col-md-9 col-lg-10 d-flex align-items-start p-0">
      <input 
        id="queryInput"
        type="text"
        class="form-control search-input flex-grow-1"
        formControlName="query" 
        [placeholder]="searchBarPlaceholder"
      />
      <button 
            type="submit" 
            class="btn btn-primary search-button ms-4 align-self-end" 
            [disabled]="isLoading || isRagLoading || isSearchButtonDisabled"
        >
        <span *ngIf="!isLoading">{{ searchButtonLabel }}</span>
        <div *ngIf="isLoading" class="spinner-border" role="status"></div>
      </button>
    </div>
  </form>

  <!-- Prompts Dropdown + AI Response Toggle -->
  <div class="row mt-3 mb-0 align-items-center gx-3 flex-wrap">
    <!-- Combined Column for Prompts & AI Toggle -->
    <div class="offset-md-2 col-md-4 d-flex align-items-center px-0 justify-content-start">

      <!-- Conditionally Render Prompts Section -->
      <div *ngIf="promptsExists" class="d-flex align-items-center">
        <label for="promptsDropdown" class="prompts-label fw-bold me-2"> 
          AI PROMPT: 
        </label>
        <div class="dropdown">
          <button 
            class="btn btn-outline-secondary dropdown-toggle text-truncate text-start me-3" 
            type="button" 
            id="promptsDropdown"
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            title="{{ selectedPromptName }}"
          >
            {{ selectedPromptName }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="promptsDropdown">
            <li *ngFor="let prompt of prompts">
              <button 
                class="dropdown-item d-flex justify-content-between align-items-center text-truncate" 
                type="button" 
                (click)="selectPrompt(prompt.value)" 
                title="{{ prompt.name }}"
                [ngClass]="{'selected': prompt.value === searchForm.get('selectedPrompt')?.value}" 
                [disabled]="isLoading || !hasResults"
              >
                <span 
                  class="text-truncate" 
                  [ngClass]="{'default-source': prompt.value === defaultPromptValue}"
                >
                  {{ prompt.name }}
                </span>
                <span 
                  *ngIf="prompt.value === searchForm.get('selectedPrompt')?.value" 
                  class="custom-checkmark"
                >
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- AI Response Toggle (Now Inside Same Column) -->
      <div class="d-flex align-items-center">
        <div class="form-check form-switch custom-toggle">
          <input
            class="form-check-input"
            type="checkbox"
            id="aiResponseToggle"
            [checked]="checkedGenerateAiResponseControl?.value"
            (change)="toggleChange($event)"
            [disabled]="isLoading || !hasResults"
          />
          <label class="form-check-label ms-2" for="aiResponseToggle">
            {{ aiResponseLabel }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-container>
