import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ChatBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  // Input to determine if auto-focus should be enabled
  @Input() autoFocus: boolean = false;

  // Reference to the textarea element for focusing
  @ViewChild('inputElement') inputElement!: ElementRef;

  @Input() isConversation: boolean = false;
  searchForm: UntypedFormGroup;
  searchButtonLabel: string = 'SEND';
  searchBarPlaceholder: string = 'Ask me anything, I\'m here to help...';

  messages: any[] = [];
  chatId: number | undefined;
  isLoading: boolean = false; // Initialize isLoading as false

  private messagesSubscription: Subscription | undefined;
  private isConversationSubscription: Subscription | undefined;
  private isLoadingSubscription: Subscription | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private chatService: ChatService,
    private swirlService: SwirlService,
    private route: ActivatedRoute
  ) {
    // Initialize the searchForm and set the disabled state dynamically
    this.searchForm = this.fb.group({
      query: [{ value: '', disabled: this.isLoading }] // Initially disabled if isLoading is true
    });
  }

  get isSearchButtonDisabled(): boolean {
    return this.searchForm.get('query')?.value?.trim() === '';
  }

  ngOnInit() {
    // Extract chatId from the query parameters
    this.route.queryParams.subscribe(params => {
      const chatIdParam = params['chat_id']; // Retrieve chat_id from the URL
      if (chatIdParam) {
        this.chatId = +chatIdParam; // Convert to number
        this.chatService.setChatId(this.chatId); // Set the chatId in the service
        this.subscribeToMessages(this.chatId); // Subscribe to messages for the chatId
        this.fetchMessages(this.chatId); // Fetch the messages for the chatId
      } else {
        console.warn('No chat_id found in the URL. Starting a new chat session.');
      }
    });

    // Subscribe to conversation status changes
    this.isConversationSubscription = this.chatService.isConversationStarted$.subscribe(isStarted => {
      if (isStarted && this.chatId !== undefined) {
        this.fetchMessages(this.chatId);
      }
    });

    // Subscribe to isLoading state from ChatService
    this.isLoadingSubscription = this.chatService.isLoading$.subscribe(isLoading => {
      this.setLoadingState(isLoading);
    });
  }

  ngAfterViewInit(): void {
    // Auto-focus the input element if autoFocus is true
    if (this.autoFocus) {
      this.focusInput();
    }
  }

  // Method to subscribe to messages observable with chatId
  private subscribeToMessages(chatId: number) {
    // Unsubscribe from previous subscription if any
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }

    // Subscribe to the messages observable for the current chatId
    this.messagesSubscription = this.chatService.getMessagesObservable(chatId).subscribe({
      next: (updatedMessages) => {
        this.messages = updatedMessages;
        // After receiving new messages, ensure the input remains focused
        if (this.autoFocus) {
          this.focusInput();
        }
      },
      error: (error) => {
        console.error('Error subscribing to messages:', error);
      },
    });
  }

  fetchMessages(chatId: number) {
    this.swirlService.getMessages(chatId).subscribe({
      next: (messages) => {
        this.chatService.setChatId(chatId);
        this.chatService.setMessages(chatId, messages);
        this.messages = this.chatService.getMessages(chatId);
        // After fetching messages, ensure the input remains focused
        if (this.autoFocus) {
          this.focusInput();
        }
      },
      error: (error) => {
        console.error('Error fetching messages:', error);
      },
    });
  }

  // Dynamically manage the isLoading state and enable/disable the form control
  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;

    const queryControl = this.searchForm.get('query');
    if (isLoading) {
      queryControl?.disable(); // Disable the control when loading
    } else {
      queryControl?.enable(); // Enable the control when not loading
    }

    // After changing loading state, refocus the input if autoFocus is enabled
    if (this.autoFocus) {
      this.focusInput();
    }
  }

  // Handle Ctrl + Enter for new lines
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
        // Ctrl + Enter: Add a new line
        event.preventDefault(); // Prevent default Enter behavior
        const queryControl = this.searchForm.get('query');
        const currentValue = queryControl?.value || '';
        queryControl?.setValue(currentValue + '\n'); // Add a new line
      } else {
        // Enter: Submit the form
        event.preventDefault(); // Prevent default Enter behavior
        this.onSubmit();
      }
    }
  }

  onSubmit() {
    if (this.searchForm.valid) {
      const query = this.searchForm.get('query')?.value.trim();
      if (!query) return; // Do not submit empty messages
  
      // Add user's message to the messages array immediately
      const userMessage = { role: 'user', content: query };
  
      // Check if chatId is available
      let currentChatId = this.chatService.getChatId();
      if (currentChatId === undefined) {
        // Initialize new chat session
        this.swirlService.createChat().subscribe({
          next: (response) => {
            this.chatId = response.id || undefined;
            if (this.chatId === undefined) {
              console.error('Invalid chat ID received from createChat.');
              return;
            }
            this.chatService.setChatId(this.chatId);
            this.chatService.changeStatus(true);
            // Add user message to the new chat session
            this.chatService.addMessage(this.chatId, userMessage);
  
            // Subscribe to messages for the new chatId
            this.subscribeToMessages(this.chatId);
  
            // Set loading state via ChatService and proceed with sending the message
            this.chatService.setLoading(true);
            this.sendMessage(query, this.chatId);
          },
          error: (error) => {
            console.error('Error initializing chat session:', error);
          },
        });
      } else {
        this.chatId = currentChatId;
        // Add user message to the existing chat session
        this.chatService.addMessage(this.chatId, userMessage);
        this.chatService.changeStatus(true);
  
        // Subscribe to messages for the existing chatId if not already subscribed
        if (!this.messagesSubscription || this.messagesSubscription.closed) {
          this.subscribeToMessages(this.chatId);
        }
  
        // Set loading state via ChatService and proceed with sending the message
        this.chatService.setLoading(true);
        this.sendMessage(query, this.chatId);
      }
  
      // Reset the form
      this.searchForm.reset();
  
      // Refocus the input after submitting
      if (this.autoFocus) {
        this.focusInput();
      }
    }
  }

  sendMessage(query: string, chatId: number) {
    this.swirlService.sendMessage(query, chatId).subscribe({
      next: (response) => {
        this.chatService.addMessage(chatId, {
          role: 'assistant',
          time: response.time,
          content: response.content,
          additional_content: response.additional_content,
        });
        this.fetchMessages(chatId);
  
        // Reset loading state via ChatService
        this.chatService.setLoading(false);
      },
      error: (error) => {
        console.error('Error sending message:', error);
  
        // Reset loading state via ChatService
        this.chatService.setLoading(false);
      },
    });
  }

  // Method to focus the input element
  private focusInput(): void {
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    if (this.isConversationSubscription) {
      this.isConversationSubscription.unsubscribe();
    }
    if (this.isLoadingSubscription) {
      this.isLoadingSubscription.unsubscribe();
    }
  }
}
