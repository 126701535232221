import { Component, ElementRef, AfterViewChecked, ViewChild, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewEncapsulation, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ChatComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('dummy') private dummy!: ElementRef; // Reference to the dummy div
  @Input() messages: any[] = [];
  parsedMessages: any[] = [];

  private shouldScroll: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private chatService: ChatService,
    private swirlService: SwirlService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.parseMessages();
  }

  ngAfterViewChecked() {
    if (this.shouldScroll) {
      requestAnimationFrame(() => {
        this.scrollToBottom();
        this.shouldScroll = false;
      });
    }
  }

  private scrollToBottom(): void {
    try {
      this.dummy.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } catch(err) {
      console.error('Scroll to bottom error:', err);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this.parseMessages();
      this.cdr.detectChanges(); // Ensure change detection runs
      this.shouldScroll = true; // Flag to trigger scrolling
    }
  }

  parseMessages(): void {
    if (this.messages) {
      this.parsedMessages = this.messages.map((message) => {
        // Sanitize and clean the main content first
        const sanitizedContent = this.formatContent(message.content);
        const cleanedContent = this.cleanUnwantedHtml(sanitizedContent);
        const fixedContent = this.sanitizeSourceLinks(cleanedContent);

        // Clean the sources in additional_content if available
        if (
          message.additional_content &&
          Array.isArray(message.additional_content.sources)
        ) {
          message.additional_content.sources = message.additional_content.sources.map((source: any) => ({
            ...source,
            url: source.url.replace(/<em>/gi, '').replace(/<\/em>/gi, ''),
            description: source.description.replace(/<em>/gi, '').replace(/<\/em>/gi, '')
          }));
        }

        // Return the updated message with cleaned content
        return {
          ...message,
          content: this.sanitizer.bypassSecurityTrustHtml(fixedContent) as SafeHtml,
        };
      });
    }
  }

  private cleanUnwantedHtml(html: string): string {
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Find and remove the unwanted <p><br><b></b></p> elements
    doc.querySelectorAll('p').forEach((p) => {
      if (p.innerHTML.trim() === '<br><b></b>') {
        p.remove();
      }
    });

    // Return the cleaned HTML as a string
    return doc.body.innerHTML;
  }

  // Sanitize the source links by encoding the href attribute
  private sanitizeSourceLinks(html: string): string {
    // This regex looks for an anchor tag with the class "sources-list-link" and captures the href value.
    return html.replace(/(<a\b(?=[^>]*\bsources-list-link\b)[^>]*\bhref=")([^"]+)(")/gi, (match, p1, p2, p3) => {
      // Remove any <em> tags from the captured URL
      let cleanUrl = p2.replace(/<em>/gi, '').replace(/<\/em>/gi, '');
      try {
        // Try to construct a URL – this will throw if the URL is invalid (e.g., contains spaces)
        new URL(cleanUrl);
        // Use encodeURI to encode spaces and other unsafe characters.
        cleanUrl = encodeURI(cleanUrl);
      } catch (e) {
        // If constructing a URL fails, replace spaces with %20.
        cleanUrl = cleanUrl.replace(/ /g, '%20');
      }
      return p1 + cleanUrl + p3;
    });
  }

  getFaviconUrl(url: string): string {
    // Remove any <em> tags from the URL before processing
    const cleanUrl = url.replace(/<em>/gi, '').replace(/<\/em>/gi, '');
    try {
      const domain = new URL(cleanUrl).origin; // Extract the domain
      return `${domain}/favicon.ico`; // Construct the favicon URL
    } catch {
      return ''; // Fallback to an empty string if the URL is invalid
    }
  }

  handleFaviconError(event: Event): void {
    const img = event.target as HTMLImageElement;
    img.style.display = 'none'; // Hide the image if it fails to load
  }

  formatContent(content: string): string {
    return content
      .replace(/>\n+/g, '>')   // Remove newlines right after opening tags (like <p>, <div>, etc.)
      .replace(/\n/g, '<br>')   // Replace remaining newlines with <br>
      .replace(/^(<br\s*\/?>)+/, '');
  }

  // HostListener to capture all click events within the component
  @HostListener('click', ['$event'])
  handleLinkClick(event: Event) {
    const target = event.target as HTMLElement;
    // Look up the DOM tree for the nearest ancestor with the 'question-link' class
    const questionLink = target.closest('.question-link') as HTMLElement;

    this.chatService.isLoading$.subscribe(isLoading => {
      if (questionLink && !isLoading) {
        event.preventDefault(); // Prevent default link behavior

        const question = questionLink.textContent?.trim();
        if (question) {
          this.sendFollowUpQuestion(question);
        }
      }
    }).unsubscribe(); // Unsubscribe immediately to prevent memory leaks
  }

  // Method to send the follow-up question using SwirlService and ChatService
  sendFollowUpQuestion(question: string) {
    const chatId = this.chatService.getChatId();
    if (chatId === undefined) {
      console.error('No active chat session found.');
      return;
    }

    // Create the user message object
    const userMessage = { role: 'user', content: question };

    // Add the user's follow-up question to ChatService
    this.chatService.addMessage(chatId, userMessage);

    // Set loading state via ChatService and proceed with sending the message
    this.chatService.setLoading(true);
    this.sendMessage(question, chatId);
  }

  sendMessage(query: string, chatId: number): void {
    this.swirlService.sendMessage(query, chatId).subscribe({
      next: (response) => {
        this.chatService.addMessage(chatId, {
          role: 'assistant',
          time: response.time,
          content: response.content,
          additional_content: response.additional_content,
        });
        // Scrolling is handled in ngAfterViewChecked via shouldScroll flag
        this.chatService.setLoading(false); // Reset loading state
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error sending follow-up question:', error);
        // Optionally, add an error message to the chat
        const errorMessage = {
          role: 'system',
          content: 'Sorry, there was an error processing your request. Please try again later.',
        };
        this.chatService.addMessage(chatId, errorMessage);
  
        // Reset loading
        this.chatService.setLoading(false);
      },
    });
  }
}
