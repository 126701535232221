<div class="w-100 d-flex flex-column min-vh-100">
  <!-- Navbar Section -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm align-items-start">
    <div class="container-fluid">
      <div class="row w-100 align-items-start gx-0">
        <!-- Logo -->
        <div class="col-md-3 col-lg-2 d-flex justify-content-center align-items-center pt-4">
          <a
            class="navbar-brand"
            href="chat"
            (click)="handleLogoClick()"
          >
            <img 
              src="/api/swirl/sapi/branding/?target=light"
              alt="SWIRL logo" 
              class="logo-img"
            />
          </a>
        </div>

        <!-- Profile Icon Column -->
        <div class="col-auto d-flex justify-content-end ms-auto pt-4 pe-4">
          <chat-profile-box></chat-profile-box>
        </div>
      </div>
    </div>
  </nav>

  <!-- Main Content Section -->
  <main 
    #mainContainer
    class="container-fluid flex-fill d-flex flex-column overflow-auto"
    [class.align-items-center]="!isConversationStarted"
    [class.justify-content-center]="!isConversationStarted"
  >
    <!-- Chat Window -->
    <div *ngIf="isConversationStarted" class="mt-3 w-100">
      <app-chat [messages]="messages"></app-chat>
    </div>

    <!-- Centered Chat Box for New Sessions -->
    <div *ngIf="!isConversationStarted && isAIProviderExists" class="d-flex justify-content-center chat-box-wrapper">
      <app-chat-box class="chat-box" [autoFocus]="true"></app-chat-box>
    </div>

    <!-- No AIProviders Alert -->
    <div *ngIf="isAIProviderLoaded && !isAIProviderExists" 
         class="error-text text-center" role="alert">
      There are no AIProviders configured.
    </div>
  </main>

  <!-- Fixed Bottom Chat Box for Ongoing Sessions -->
  <footer 
    class=" bg-light border-top bg-light shadow-sm py-2" 
    *ngIf="isConversationStarted && isAIProviderExists; else noFooter">
    <div class="container-fluid d-flex justify-content-center">
      <div class="chat-box-wrapper">
        <app-chat-box class="chat-box" [autoFocus]="true"></app-chat-box>
      </div>
    </div>
  </footer>
  <ng-template #noFooter></ng-template>
</div>
