<div class="d-flex flex-wrap gap-2">
  <button
    type="button"
    class="btn btn-secondary btn-sm"
    *ngFor="let action of actions; let i = index"
    (click)="prepareAndApply(i)"
  >
    {{ action.label }}
  </button>
</div>

<!-- Native Bootstrap Modal -->
<div
  class="modal fade"
  id="actionModal"
  tabindex="-1"
  aria-labelledby="actionModalLabel"
  aria-hidden="true"
  #actionModal
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="actionModalLabel">Action Selector</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <!-- Insert dynamic content here -->
        <p *ngIf="currentAction">
          Selected Action: {{ currentAction.label }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="apply(currentAction)">
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
