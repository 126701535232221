<div class="row gx-0" *ngIf="isShowing && isRagParamInUrl">
  <div class="col">
    <!-- AI Summary Header Row -->
    <div class="d-flex justify-content-between align-items-center mb-2">
      <!-- Left-aligned Heading -->
      <h5 class="ai-summary-heading m-0">AI Summary:</h5>

      <!-- Right-aligned Metadata -->
      <div class="ai-summary-metadata text-end" *ngIf="text !== 'There are no AIProviders configured.'">
        <p class="m-0" *ngIf="qSummaryTimeSeconds > 0 && !aiModel">
          AI Summary generated in {{qSummaryTimeSeconds}} seconds.
        </p>
        <p class="m-0" *ngIf="qSummaryTimeSeconds > 0 && aiModel">
          AI Summary generated by {{aiModel}} in {{qSummaryTimeSeconds}} seconds.
        </p>
      </div>
    </div>

    <!-- Error Message -->
    <div *ngIf="text === 'There are no AIProviders configured.' && !isLoading" class="ai-error-wrapper">
      {{ text }}
    </div>

    <!-- Loading Indicator -->
    <div class="d-flex justify-content-start position-relative">
      <div *ngIf="isLoading" class="spinner-border ai-summary-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- AI Summary Text -->
    <div *ngIf="!isLoading && text !== 'There are no AIProviders configured.'" class="ai-summary-text" [innerHtml]="text"></div>
  </div>
</div>
