<!-- Layout Summary -->
<div *ngIf="layout.summary" class="summary px-3 pb-0">
  <div class="row g-3" *ngFor="let row of layout.summary">
    <div
      class="col"
      [ngClass]="{
        'col-5': i % 2 === 1 && lists.length > 1,
        'col-7': i % 2 === 0 && lists.length > 1,
        'col-12': lists.length === 1
      }"
      *ngFor="let lists of row; let i = index"
    >
      <dl class="mb-0" *ngFor="let list of lists">
        <div
          class="row g-2 align-items-center"
          [ngClass]="'type-' + item.type + ' ' + item.custom_field_type"
          [attr.data-fields]="item.fields"
          [style.color]="item.color || ''"
          [style.fontStyle]="item.font_style || ''"
          *ngFor="let item of list"
        >
          <ng-container *ngIf="!item.hidden_values || (item.fields && item.fields.length > 0 && doc && doc[item.fields[0]] && !item.hidden_values.includes(doc[item.fields[0]][0]))">
            <!-- Title Field and Checkbox -->
            <ng-container *ngIf="item.type === 'title'">
              <div class="d-flex align-items-center p-0">
                <!-- Checkbox -->
                <div *ngIf="isCheckboxRendered" class="me-2">
                  <input
                    type="checkbox"
                    [(ngModel)]="isChecked"
                    (change)="check()"
                    class="form-check-input"
                  />
                </div>

                <!-- Result Number -->
                <div class="result-number">
                  <span>{{ doc[item.fields[1]] }}.</span>
                </div>

                <!-- Title Content -->
                <div class="flex-grow-1">
                  <app-title-field
                    [fields]="item.fields"
                    [doc]="doc"
                    [item]="item"
                    class="w-100"
                  ></app-title-field>
                </div>
              </div>
            </ng-container>

            <!-- Body Field -->
            <ng-container *ngIf="item.type === 'body'">
              <div class="result-body text-wrap overflow-hidden">
                <app-body-field
                  [fields]="item.fields"
                  [doc]="doc"
                  [item]="item"
                  class="w-100"
                ></app-body-field>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </dl>
    </div>
  </div>
</div>

<!-- Layout Details -->
<div *ngIf="showDetailsEnabled && showDetails" class="p-3 pb-0">
  <div class="row g-3 align-items-start" *ngFor="let row of layout.details">
    <div
      class="col"
      [ngClass]="{
        'col-5': i % 2 === 1 && lists.length > 1,
        'col-7': i % 2 === 0 && lists.length > 1,
        'col-12': lists.length === 1
      }"
      *ngFor="let lists of row; let i = index"
    >
      <dl class="mb-0" *ngFor="let list of lists">
        <div
          class="row g-2 align-items-center"
          [ngClass]="'type-' + item.type + ' ' + item.custom_field_type"
          [attr.data-fields]="item.fields"
          [style.color]="item.color || ''"
          [style.fontStyle]="item.font_style || ''"
          *ngFor="let item of list"
        >
          <ng-container *ngIf="!item.hidden_values || (item.fields && item.fields.length > 0 && doc && doc[item.fields[0]] && !item.hidden_values.includes(doc[item.fields[0]][0]))">
            <!-- JSON Field -->
            <ng-container *ngIf="item.type === 'json' && item.fields && item.fields.length > 0 && (doc[item.fields[0]] | json) !== '{}'">
              <div class="details">
                <ng-container *ngIf="item.label">
                  <dt class="fw-bold text-nowrap">{{ item.label }}:</dt>
                </ng-container>
                <app-json-field
                  [fields]="item.fields"
                  [doc]="doc"
                  [item]="item"
                ></app-json-field>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </dl>
    </div>
  </div>
</div>

<!-- Bottom Row -->
<div class="row g-0 align-items-center pt-2">
  <!-- Star Icon -->
  <div class="col-auto d-flex align-items-center mx-2">
    <img 
      *ngIf="shouldShowStar()" 
      src="assets/images/icon_star_solid.png" 
      alt="Star Icon" 
      class="star"
    />
    <div *ngIf="!shouldShowStar()" class="star-placeholder"></div>
  </div>

  <!-- Authorship Field -->
  <div class="col text-truncate">
    <app-authorship-field
      [fields]="['provider', 'url']"
      [doc]="doc"
      [item]="doc.authorship"
      class="text-start authorship"
    ></app-authorship-field>
  </div>

  <!-- Show/Hide Details Link -->
  <div class="col-auto text-end">
    <button
      type="button"
      class="btn btn-link show-details"
      (click)="toggleDetails()"
      [disabled]="!showDetailsEnabled || !layout.details"
    >
      {{ showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS' }}
    </button>
  </div>
</div>
