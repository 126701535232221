import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigService } from '../config.service';
import { Action, ConfigResponse } from '../shared/config-response';
import { SearchService } from '../search.service';
import { SearchContext } from '../shared/search-context';
import { ShoppingCartService } from '../shopping-cart.service';
import { ShoppingCart } from '../shared/shopping-cart';
import Modal from 'bootstrap/js/dist/modal'; // Import only the Modal class

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  standalone: false
})
export class ActionsComponent implements OnInit {
  actions: Array<Action> = [];
  config: ConfigResponse = new ConfigResponse();
  searchContext: SearchContext = new SearchContext();
  cart: ShoppingCart = new ShoppingCart();
  currentAction: Action | null = null;

  @ViewChild('actionModal', { static: false }) actionModal!: ElementRef;

  constructor(
    private configService: ConfigService,
    private searchService: SearchService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
    this.configService.getConfig().subscribe(configResponse => {
      if (configResponse) {
        this.config = configResponse;
        if (configResponse.actions) {
          this.actions = configResponse.actions;
        }
      }
    });

    this.searchService.currentSearchContext.subscribe((searchContext: SearchContext) => {
      this.searchContext = searchContext;
    });

    this.shoppingCartService.currentShoppingCart.subscribe((cart: ShoppingCart) => {
      this.cart = cart;
    });
  }

  prepareAndApply(index: number): void {
    const action = this.actions[index];
    if (action.useSelector) {
      this.selectParamsAndApply(action);
    } else {
      this.apply(action);
    }
  }

  selectParamsAndApply(action: Action): void {
    this.currentAction = action; // Assign action to currentAction
    const modal = new Modal(this.actionModal.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
    modal.show();
  }

  apply(action: Action | null): void {
    if (!action) {
      console.error('No action provided to apply.');
      return;
    }

    let urlstring = action.url + '?actionId=' + encodeURIComponent(action.id);

    action.parameters.forEach(parameter => {
      if (parameter.type === 'config') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.config[parameter.value]);
      } else if (parameter.type === 'static') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(parameter.value);
      } else if (parameter.type === 'actionParameterValues') {
        const values = this.config.actionParameterValues[parameter.value].values;
        values.forEach(value => {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(value.value);
        });
      } else if (parameter.type === 'SearchContext') {
        if (parameter.value === 'refine') {
          const refinementMap = SearchContext.refinementMap(this.searchContext.refine);
          for (let entry of refinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.facetString(entry[0], entry[1].join(this.searchContext.separator)));
          }
          const rangeRefinementMap = SearchContext.refinementMap(this.searchContext.refine, 'range');
          for (let entry of rangeRefinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.rangeFacetString(entry[0], entry[1]));
          }
        } else {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext[parameter.value]);
        }
      } else if (parameter.type === 'cart') {
        const items = this.cart.items.values();
        for (let item of items) {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(item[parameter.value]);
        }
      }
    });

    window.open(urlstring, action.target);
  }
}
