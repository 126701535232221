<div *ngIf="!isLoading && isShowing" class="d-flex align-items-center h-100">
  <form [formGroup]="sortForm" class="d-flex align-items-center w-100">
    <label for="sortingDropdown" class="me-2 text-nowrap sorting-label">VIEW BY:</label>
    <div class="dropdown w-100">
      <button
        class="btn btn-outline-secondary dropdown-toggle w-100 text-truncate text-start"
        type="button"
        id="sortingDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        title="{{ selectedSortingName }}"
      >
        {{ selectedSortingName }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="sortingDropdown">
        <li *ngFor="let sorting of sortings" [hidden]="sorting.value === 'RelevancyConfidenceMixer' && !confidence_mixer">
          <button
            class="dropdown-item d-flex justify-content-between align-items-center text-truncate"
            type="button"
            (click)="selectSorting(sorting.value)"
            [ngClass]="{ 'selected': sorting.value === sortForm.get('selectedSorting')?.value }"
            [attr.title]="sorting.name"
          >
            <span class="text-truncate">{{ sorting.name }}</span>
            <span 
              *ngIf="sorting.value === sortForm.get('selectedSorting')?.value"
              class="custom-checkmark"
            ></span>
          </button>
        </li>
      </ul>
    </div>
  </form>
</div>
