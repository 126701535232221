import { Component, OnInit } from '@angular/core';
import { SwirlService } from '../swirl.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { SearchService } from '../search.service';
import { LocalStorageService } from '../local-storage.service';
import { Microsoft } from 'src/app/auth/microsoft.service';
import { MsalAccessService } from '../../auth/msal-service';
import { Authenticators } from '../../auth';
import { ConfigService } from '../config.service';
import { ReloadService } from '../reload.service';  // <-- import the new service

const AuthenticatorsList: any = Authenticators;
let AuthenticatorsServices: Record<string, any> = {};

@Component({
  selector: 'chat-profile-box',
  templateUrl: './chat-profile-box.component.html',
  styleUrls: ['./chat-profile-box.component.scss'],
  standalone: false
})
export class ChatProfileBoxComponent implements OnInit {
  user: string = '';
  swirlVersion: string | null = '';
  mode: string = localStorage.getItem('isDark') === 'Dark' ? 'Light' : 'Dark';
  swirlAdminLink: string = '';

  authenticators: { idp: string, expired: boolean }[] | any[] = [];
  isEnabledCharts: boolean = false;

  constructor(
    private swirl: SwirlService,
    private searchService: SearchService,
    private authService: AuthService,
    private router: Router,
    private msalInstance: MsalAccessService,
    private msauthService: Microsoft,
    private localStorageService: LocalStorageService,
    private configService: ConfigService,
    private reloadService: ReloadService  // <-- inject the service
  ) {
    if (this.msalInstance.microsoftGalaxyOauth) {
      // Swirl Public repo logic
      AuthenticatorsServices[msauthService.name] = msauthService;
    }
  }

  async loadAuthenticators() {
    if (this.msalInstance.microsoftGalaxyOauth) {
      // Swirl Public repo logic
      AuthenticatorsList.forEach((a: any) => {
        if (!(a in AuthenticatorsServices)) {
          console.warn(`Authenticator ${a} listed but not defined`);
        }
      });
      this.authenticators = await Promise.all(Object.keys(AuthenticatorsServices).map(async (authenticatorName: string) => {
        if (AuthenticatorsServices[authenticatorName]) {
          const token = AuthenticatorsServices[authenticatorName].getToken();
          if (token && AuthenticatorsServices[authenticatorName].isTokenExpired(token) || !token) {
            await AuthenticatorsServices[authenticatorName].updateToken();
          }
          return Promise.resolve({
            name: authenticatorName,
            idp: AuthenticatorsServices[authenticatorName].idp,
            expired: !AuthenticatorsServices[authenticatorName].isAuthenticated()
          });
        }
        return false;
      }));
      this.authenticators = this.authenticators.filter((i: any) => i);
    }
    else {
      this.swirl.getAuthenticators().subscribe(response => {
        this.authenticators = response.map((a: any) => ({
          ...a,
          idp: a.idp,
          name: a.name || a.idp
        }));
      });
    }
  }

  async ngOnInit() {
    this.configService.getConfig('default').subscribe(configResponse => {
      if (configResponse) {
        if (configResponse.swirlBaseURL)
          this.swirlAdminLink = configResponse.swirlBaseURL;
      }
    });
    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts);
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Light' : 'Dark';
    });

    this.user = this.authService.getUser() || '';
    this.swirlVersion = this.authService.getSwirlVersion();
    await this.loadAuthenticators();
  }

  toggleChange(event: any, idp: string, name: string, expired: boolean) {
    if (this.msalInstance.microsoftGalaxyOauth) {
      // Swirl Public repo logic
      if (!AuthenticatorsServices[name].isAuthenticated()) {
        AuthenticatorsServices[name].login();
      }
      else {
        AuthenticatorsServices[name].logout();
      }
    }
    else {
      if (expired)
        this.authService.redirectToAuth(idp);
      else {
        this.swirl.removeToken(idp).subscribe(() => {
          this.loadAuthenticators();
        });
      }
    }
  }

  toggleTheme() {
    this.localStorageService.setItem('isDark', this.mode);
  }

  toggleCharts() {
    this.searchService.enabledCharts.next(!this.isEnabledCharts);
  }

  logout() {
    this.authService.logout().subscribe(
      (data: any) => {
        sessionStorage.clear();
        localStorage.clear();
        this.reloadService.reload(); 
      },
      (error: any) => {
        sessionStorage.clear();
        localStorage.clear();
        console.log(error);
        this.reloadService.reload();
      }
    );
  }
}
